import { Component, OnInit } from '@angular/core';
import { TRANSLATE_KEYS } from '../../../shared/constants/translate.keys';
import { HomeService } from '../../../home/home.service';
import { MyCallsService } from '../my-calls.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'src/app/shared/components/toaster/toaster.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-my-calls-list',
    templateUrl: './my-calls-list.component.html',
    styleUrls: ['./my-calls-list.component.scss']
})
export class MyCallsListComponent implements OnInit {
    translateKeys = TRANSLATE_KEYS;
    searchValue: any = '';
    showAddModal = false;
    showCallDetailsModal = false;
    showAddNewSection = false;
    callListLoading = false;
    isLoading: boolean = false;
    callData: any = {};
    editCallId: any;
    tableConfig = {
        pageIndex: 1,
        pageSize: 8,
        totalCount: 0,
        search: '',
        sortKey: 'createdDate',
        sort: 'desc'
    };
    showQuickJumper: boolean = true;
    callListData: any = [];

    callListTableColumns = [
        {
            name: 'Call Title',
            key: 'callTitle',
            translateKey: 'myCallsGrid.callTitle',
            dataType: 'string',
            align: 'left',
            defaultSortOrder: null,
            sortDirections: ['ascend', 'descend'],
            sortable: true,
            linkColumn: true,
            linkColumnAssociatedKey: ['callTitle'],
            headerStyle: {
                fontWeight: 600,
                borderBottom: '1px solid #9C9AA1',
                fontSize: '14px'
            },
            style: {
                fontWeight: 600,
                fontSize: '16px',
                color: '#27a6a4',
                cursor: 'pointer'
            },
            type: 'text',
            width: '50%'
        },
        {
            name: 'Specialty Name',
            key: 'specialtyName',
            dataType: 'string',
            align: 'left',
            defaultSortOrder: null,
            sortDirections: ['ascend', 'descend'],
            sortable: true,
            headerStyle: {
                fontWeight: 600,
                borderBottom: '1px solid #9C9AA1',
                fontSize: '14px'
            },
            style: { fontWeight: 400, fontSize: '16px', color: '#454250' },
            type: 'text',
            width: '20%'
        },
        {
            name: 'Created Date',
            key: 'createdDate',
            dataType: 'date',
            defaultSortOrder: 'desc',
            sortDirections: ['ascend', 'descend'],
            sortable: true,
            align: 'left',
            type: 'date',
            dateFormat: 'd MMM y',
            headerStyle: {
                fontWeight: 600,
                borderBottom: '1px solid #9C9AA1',
                fontSize: '14px'
            },
            style: { fontWeight: 400, fontSize: '16px', color: '#454250' },
            width: '18%'
        },
        {
            name: 'Modified Date',
            key: 'modifiedDate',
            dataType: 'date',
            defaultSortOrder: null,
            sortDirections: ['ascend', 'descend'],
            sortable: true,
            align: 'left',
            type: 'date',
            dateFormat: 'd MMM y',
            headerStyle: {
                fontWeight: 600,
                borderBottom: '1px solid #9C9AA1',
                fontSize: '14px'
            },
            style: { fontWeight: 400, fontSize: '16px', color: '#454250' },
            width: '18%'
        },
        {
            name: 'Actions',
            key: 'actions',
            dataType: 'string',
            defaultSortOrder: null,
            sortDirections: ['ascend', 'descend'],
            width: '14%',
            showIcon: true,
            sortable: false,
            type: 'actions',
            align: 'center',
            actionIcons: [
                {
                    type: 'custom:score-card',
                    key: 'scoreCardDisable',
                    title: 'Stay Tuned For Call Summary',
                    theme: 'outline'
                },
                {
                    type: 'custom:score-card',
                    key: 'scoreCardHCPConsentDisable',
                    title: "This call doesn't seem to have consent agreed, call summaries will not be generated and the call will be discarded",
                    theme: 'outline'
                },
                {
                    type: 'custom:score-card',
                    key: 'scoreCardPartial',
                    title: 'Call Summary Available',
                    theme: 'outline'
                },
                {
                    type: 'custom:score-card',
                    key: 'scoreCard',
                    title: 'Call Scorecard Available',
                    theme: 'outline'
                },
                {
                    type: 'custom:pencil',
                    key: 'edit',
                    title: 'Edit',
                    theme: 'fill'
                }
            ]
        }
    ];

    readonly scrollDetails = { x: '800px', y: '55vh' };

    private callsSub: Subscription | undefined;

    appConfig: any;

    constructor(
        private myCallsService: MyCallsService,
        private toasterService: ToasterService,
        private translateService: TranslateService,
        private router: Router,
        private homeService: HomeService
    ) {
        this.appConfig = this.homeService.getAppConfig();
    }

    ngOnInit(): void {
        this.getCallList();
    }

    handleTablePageChange(pageNumber: number): void {
        this.tableConfig.pageIndex = pageNumber;
        this.getCallList();
    }

    sortBy(event: any) {
        this.tableConfig.sortKey = event.key;
        this.tableConfig.sort = event.defaultSortOrder;
        this.getCallList();
    }

    translateColumnName() {
        let result: any;
        this.callListTableColumns.forEach((column: any) => {
            if (column.translateKey) {
                this.translateService
                    .get(column.translateKey)
                    .subscribe((res: any) => {
                        column.name = res;
                    });
            }
        });
        this.callListTableColumns = [...this.callListTableColumns];
    }

    getCallList() {
        this.callListLoading = true;
        const payload = {
            searchValue: this.tableConfig.search || '',
            pageNo: this.tableConfig.pageIndex,
            count: this.tableConfig.pageSize,
            sortKey: this.tableConfig.sortKey,
            sortValue: this.tableConfig.sort,
            countryId: this.appConfig.countryId,
            drugId: this.appConfig.drugId
        };
        this.callsSub?.unsubscribe();
        this.callsSub = this.myCallsService.callsGrid(payload).subscribe(
            (res) => {
                if (res?.data?.length) {
                    res?.data.forEach((row: any) => {
                        row['visibleActionIcons'] = ['edit'];
                        if (!row?.consentByHCP && row?.consentByHCP !== null) {
                            if (environment?.clientConfig?.showScoreCard)
                                row['visibleActionIcons'].push(
                                    'scoreCardHCPConsentDisable'
                                );
                            row['disabledActionIcons'] = [
                                'scoreCardHCPConsentDisable'
                            ];
                        } else if (
                            row?.status.toLowerCase() === 'partially completed'
                        ) {
                            if (environment?.clientConfig?.showScoreCard)
                                row['visibleActionIcons'].push(
                                    'scoreCardPartial'
                                );
                            row['disabledActionIcons'] = [];
                        } else if (row?.status.toLowerCase() === 'validated') {
                            if (environment?.clientConfig?.showScoreCard)
                                row['visibleActionIcons'].push('scoreCard');
                            row['disabledActionIcons'] = [];
                        } else {
                            if (environment?.clientConfig?.showScoreCard) {
                                row['disabledActionIcons'] = [
                                    'scoreCardDisable'
                                ];
                                row['visibleActionIcons'].push(
                                    'scoreCardDisable'
                                );
                            } else {
                                row['disabledActionIcons'] = [];
                            }
                        }
                    });
                    this.callListData = res?.data;
                    this.tableConfig.totalCount = res?.totalRecords;
                } else {
                    this.callListData = [];
                }
                this.showAddNewSection =
                    payload.pageNo === 1 &&
                    !payload.searchValue &&
                    !res?.data?.length;
                this.callListLoading = false;
            },
            (error) => {
                this.callListLoading = false;
                if (error) {
                    this.showNotification(
                        'error',
                        this.translateKeys?.common?.somethingWentWrong
                    );
                }
            }
        );
    }

    showNotification(type: notification = 'warning', messageKey = 'Message') {
        this.translateService.get(messageKey).subscribe((res) => {
            const message = res;
            switch (type) {
                case 'warning':
                    this.toasterService.toast('warning', message);
                    break;
                case 'success':
                    this.toasterService.toast('success', message);
                    break;
                case 'error':
                    this.toasterService.toast('error', message);
                    break;
            }
        });
    }

    openModal() {
        this.showAddModal = true;
        this.editCallId = null;
    }

    onSearch(event: any) {
        this.tableConfig.search = event;
        this.getCallList();
    }

    onTableRowClick(event: any) {
        if (event.icon.key === 'edit') {
            this.editCallId = event?.data?.id;
            this.showAddModal = true;
        } else if (event?.icon?.key === 'scoreCard') {
            if (environment?.clientConfig?.showScoreCard) {
                localStorage.setItem(
                    'scoreCardStatus',
                    event?.data?.status?.toLowerCase()
                );
                this.router.navigate(['/my-calls/score-card', event?.data?.id]);
            }
        }
    }
    onTableColumnClick(event: any) {
        if (environment.clientConfig.showScoreCard) {
            if (
                event?.columnData?.status?.toLowerCase() ===
                    'partially completed' ||
                event?.columnData?.status?.toLowerCase() === 'validated'
            ) {
                localStorage.setItem(
                    'scoreCardStatus',
                    event?.columnData?.status?.toLowerCase()
                );
                this.router.navigate([
                    '/my-calls/score-card',
                    event?.columnData?.id
                ]);
            }
        }
    }
    modalClosed(saved: any) {
        this.showAddModal = false;
        if (saved) {
            this.getCallList();
        }
    }
}

export type notification = 'error' | 'success' | 'warning';
