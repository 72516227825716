export const TRANSLATE_KEYS = {
    languages: [
        { label: 'English', value: 'en' },
        { label: '日本語', value: 'ja' }
    ],
    defaultLanguage: 'en',
    applicationName: 'applicationName',
    sideBar: {
        myCalls: 'sideBar.myCalls'
    },
    myCalls: {
        header: 'myCalls.header',
        addNewCall: 'myCalls.addNewCall',
        addNewCallDesc: 'myCalls.addNewCallDesc',
        addCall: 'myCalls.addCall'
    },
    scoreCard: {
        successRate: 'scoreCard.successRate'
    },
    myCallsGrid: {
        callTitle: 'myCallsGrid.callTitle',
        specialityName: 'myCallsGrid.specialityName',
        createdDate: 'myCallsGrid.createdDate',
        modifiedDate: 'myCallsGrid.modifiedDate'
    },
    newCall: {
        modalTitle: 'newCall.modalTitle',
        callTitle: 'newCall.callTitle',
        callTitlePlaceholder: 'newCall.callTitlePlaceholder',
        hcpDetails: 'newCall.hcpDetails',
        specialty: 'newCall.specialty',
        specialityPlaceholder: 'newCall.specialityPlaceholder',
        province: 'newCall.province',
        provincePlaceholder: 'newCall.provincePlaceholder',
        city: 'newCall.city',
        cityPlaceholder: 'newCall.cityPlaceholder',
        hcpSegmentation: 'newCall.hcpSegmentation',
        attitudinal: 'newCall.attitudinal',
        attitudinalPlaceholder: 'newCall.attitudinalPlaceholder',
        attitudinalInfo: 'newCall.attitudinalInfo',
        commercial: 'newCall.commercial',
        commercialPlaceholder: 'newCall.commercialPlaceholder',
        race: 'newCall.race',
        racePlaceholder: 'newCall.racePlaceholder',
        digitalPersona: 'newCall.digitalPersona',
        digitalPersonaPlaceholder: 'newCall.digitalPersonaPlaceholder',
        additionalComments: 'newCall.additionalComments',
        additionalCommentsPlaceholder: 'newCall.additionalCommentsPlaceholder',
        mrSegment: 'newCall.mrSegment',
        mrSegmentPlaceholder: 'newCall.mrSegmentPlaceholder',
        mrSegmentInfo: 'newCall.mrSegmentInfo',
        potential: 'newCall.potential',
        potentialPlaceholder: 'newCall.potentialPlaceholder',
        potentialInfo: 'newCall.potentialInfo',
        uploadDesc: 'newCall.uploadDesc',
        uploadHeader: 'newCall.uploadHeader',
        uploadType: 'newCall.uploadType',
        uploadMaxSize: 'newCall.uploadMaxSize',
        consent: 'newCall.consent',
        AE_HSIConsent: 'newCall.AE_HSIConsent',
        saveSuccessMessage: 'newCall.saveSuccessMessage',
        modalCallEditTitle: 'newCall.editCall',
        saveEditSuccessMessage: 'newCall.saveEditSuccessMessage'
    },
    common: {
        save: 'common.save',
        cancel: 'common.cancel',
        somethingWentWrong: 'common.somethingWentWrong',
        feedbackSave: 'common.feedbackSave'
    }
};
