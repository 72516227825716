import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';

@Component({
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnDestroy {
    @Input() url: any;
    @Input() fileName: any;
    @Input() isDeleteEnable = true;
    @Output()
    deleteClick: EventEmitter<any> = new EventEmitter<any>();

    wavesurfer: any;

    playingFlag = false;

    // ngAfterViewInit() {
    //   this.wavesurfer = WaveSurfer.create({
    //     container: '#waveform',
    //     waveColor: '#dedede',
    //     progressColor: '#27a6a4',
    //     barWidth: 3,
    //     barGap: 2,
    //     barRadius: 2,
    //     backgroundColor: '#ffffff',
    //     height: 80
    //   });
    //   let _wavesurfer = this.wavesurfer;
    //   this.wavesurfer.on('ready', function () {
    //     _wavesurfer?.play()
    //     _wavesurfer?.stop();
    //   });
    //   this.wavesurfer.load(this.url);
    // }

    playPause() {
        this.playingFlag = !this.playingFlag;
        if (this.playingFlag) {
            this.wavesurfer.play();
        } else {
            this.wavesurfer.stop();
        }
    }

    ngOnDestroy() {
        this.wavesurfer?.stop();
    }

    onDeleteClick() {
        this.deleteClick.emit();
    }
}
