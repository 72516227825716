export const hcpParticipation = {
    chart: {
        type: 'bar',
        height: '140px',
        events: {
            load() {
                const chart: any = this,
                    yAxis = chart.yAxis;
                yAxis[0].update({
                    // plotline tooltip for y axis
                    plotLines: [
                        {
                            customTooltip: true,
                            color: '#454250',
                            value: yAxis[0]?.plotLinesAndBands[0]?.options
                                ?.value,
                            width: '1',
                            zIndex: 4,
                            label: {
                                text: ` <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="1.28912" width="6.06" height="6" fill="white" stroke="#454250"/>
        </svg>
        `,
                                verticalAlign: 'middle',
                                textAlign: 'center',
                                x: -5,
                                useHTML: true
                            },
                            events: {
                                mouseover: function (e: any) {
                                    const plotLine =
                                        yAxis[0].plotLinesAndBands[0];

                                    var series = plotLine.axis.series[0],
                                        chart = series.chart,
                                        PointClass = series.pointClass,
                                        tooltip = chart.tooltip,
                                        point = new PointClass(series, [
                                            plotLine?.options?.value
                                        ]),
                                        normalizedEvent =
                                            chart.pointer.normalize(e);

                                    point.tooltipPos = [
                                        normalizedEvent.chartX - chart.plotLeft,
                                        normalizedEvent.chartY - chart.plotTop
                                    ];

                                    point.customTooltip =
                                        plotLine.options.customTooltip;

                                    tooltip.refresh(point);
                                },
                                mouseout: function (e: any) {
                                    const plotLine =
                                        yAxis[0].plotLinesAndBands[0];
                                    plotLine.axis.chart.tooltip.hide();
                                }
                            }
                        }
                    ]
                });
            }
        }
    },
    credits: {
        enabled: false
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        categories: ['This Call', 'Avg. of all your calls'],
        title: {
            text: null
        },
        labels: {
            style: {
                color: '#2F2C3C',
                fontSize: '14px',
                fontWeight: '400',
                fontFamily: 'Open Sans'
            }
        }
    },
    yAxis: {
        tickInterval: 20,
        gridLineColor: '#DEDCDE',
        min: 0,
        max: 100,
        step: 2,
        title: {
            text: '',
            align: 'high'
        },
        labels: {
            format: '{value}%',
            overflow: 'justify',
            style: {
                color: '#2F2C3C',
                fontSize: '14px',
                fontWeight: '400',
                fontFamily: 'Open Sans'
            }
        },
        plotLines: [
            {
                color: '#454250',
                value: 0,
                width: '1',
                zIndex: 4,
                label: {
                    text: ` <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="1.28912" width="6.06" height="6" fill="white" stroke="#454250"/>
</svg>
`,
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    x: -5,
                    useHTML: true
                }
            }
        ]
    },
    tooltip: {
        valueSuffix: '%',
        formatter: function (tooltip: any): any {
            const _this: any = this;
            if (_this.point?.customTooltip) {
                return `Avg. of all rep calls: <b>${_this.x || _this.y}%</b> `;
            } else {
                return tooltip.defaultFormatter.call(this, tooltip);
            }
        }
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true,
                format: '{y} %',
                style: {
                    color: '#2F2C3C',
                    fontSize: '16px',
                    fontWeight: '700',
                    fontFamily: 'Open Sans'
                }
            }
        },
        series: {
            pointPadding: 0,
            groupPadding: 0,
            borderRadius: 0,
            borderWidth: 0
        }
    },
    legend: {
        enabled: false
    },
    series: [
        {
            name: 'HCP Participation',
            data: [[0], [0]],
            color: '#686EFF',
            pointWidth: 20,
            pointPadding: 0,
            groupPadding: 0
        }
    ]
};
export const repParticipation = {
    chart: {
        type: 'bar',
        height: '140px',
        events: {
            load() {
                const chart: any = this,
                    yAxis = chart.yAxis;
                yAxis[0].update({
                    // plotline tooltip for y axis
                    plotLines: [
                        {
                            customTooltip: true,
                            color: '#454250',
                            value: yAxis[0]?.plotLinesAndBands[0]?.options
                                ?.value,
                            width: '1',
                            zIndex: 4,
                            label: {
                                text: ` <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="1.28912" width="6.06" height="6" fill="white" stroke="#454250"/>
        </svg>
        `,
                                verticalAlign: 'middle',
                                textAlign: 'center',
                                x: -5,
                                useHTML: true
                            },
                            events: {
                                mouseover: function (e: any) {
                                    const plotLine =
                                        yAxis[0].plotLinesAndBands[0];

                                    var series = plotLine.axis.series[0],
                                        chart = series.chart,
                                        PointClass = series.pointClass,
                                        tooltip = chart.tooltip,
                                        point = new PointClass(series, [
                                            plotLine?.options?.value
                                        ]),
                                        normalizedEvent =
                                            chart.pointer.normalize(e);

                                    point.tooltipPos = [
                                        normalizedEvent.chartX - chart.plotLeft,
                                        normalizedEvent.chartY - chart.plotTop
                                    ];

                                    point.customTooltip =
                                        plotLine.options.customTooltip;

                                    tooltip.refresh(point);
                                },
                                mouseout: function (e: any) {
                                    const plotLine =
                                        yAxis[0].plotLinesAndBands[0];
                                    plotLine.axis.chart.tooltip.hide();
                                }
                            }
                        }
                    ]
                });
            }
        }
    },
    credits: {
        enabled: false
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        categories: ['This Call', 'Avg. of all your calls'],
        title: {
            text: null
        },
        labels: {
            style: {
                color: '#2F2C3C',
                fontSize: '14px',
                fontWeight: '400',
                fontFamily: 'Open Sans'
            }
        }
    },
    yAxis: {
        tickInterval: 1,
        gridLineColor: '#DEDCDE',
        min: 0,
        title: {
            text: '',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        },
        plotLines: [
            {
                color: '#454250',
                value: '0',
                width: '1',
                zIndex: 4,
                label: {
                    text: ` <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="1.28912" width="6.06" height="6" fill="white" stroke="#454250"/>
</svg>
`,
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    x: -9,
                    useHTML: true
                }
            }
        ]
    },
    tooltip: {
        valuePrefix: '',
        formatter: function (tooltip: any): any {
            const _this: any = this;
            if (_this.point?.customTooltip) {
                return `Avg. of all rep calls: <b>${_this.x || _this.y}%</b> `;
            } else {
                return tooltip.defaultFormatter.call(this, tooltip);
            }
        }
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true,
                style: {
                    color: '#2F2C3C',
                    fontSize: '16px',
                    fontWeight: '700',
                    fontFamily: 'Open Sans'
                }
            }
        },
        series: {
            pointPadding: 0,
            groupPadding: 0,
            borderRadius: 0,
            borderWidth: 0
        }
    },
    legend: {
        enabled: false
    },
    series: [
        {
            name: 'Questions asked by you',
            data: [[0], [0]],
            pointWidth: 20,
            pointPadding: 0,
            groupPadding: 0,
            color: '#686EFF'
        }
    ]
};

export const durationChartOptions = {
    chart: {
        type: 'column',
        borderWidth: 1,
        borderColor: '#DEDCDE'
    },
    credits: {
        enabled: false
    },
    title: {
        text: null
    },
    subtitle: {
        text: null
    },
    xAxis: [
        {
            visible: false,
            categories: [],
            reversed: false
        },
        {
            visible: false,
            opposite: true,
            reversed: false
        }
    ],
    yAxis: {
        title: {
            useHTML: true,
            text: `<div class="title-container"><div>HCP’s voice</div><div>Your voice</div></div>`,
            style: {
                color: '#2f2c3c',
                fontSize: '14px',
                fontWeight: '400',
                fontFamily: 'Open Sans'
            }
        },
        labels: {
            enabled: false
        },
        gridLineWidth: 0,
        visible: true
    },

    plotOptions: {
        series: {
            stacking: 'normal',
            pointPadding: 0,
            groupPadding: 0,
            borderRadius: 0,
            borderWidth: 0
        }
    },
    tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        formatter: function (): any {
            const _this: any = this;
            const chart = _this.series.chart;
            const chartComponent = chart.chartComponent;
            const topic = chartComponent?.data[_this.x]?.insight;
            if (topic) {
                return `<div class="break-spaces"><span>Topic</span> </br> </br>${topic} </div>`;
            } else return false;
        }
    },
    series: [
        {
            name: '',
            states: {
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
            showInLegend: false,
            colorByPoint: true,
            grouping: false,
            colors: [],
            data: []
        },
        {
            name: '',
            states: {
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
            showInLegend: false,
            colorByPoint: true,
            grouping: false,
            colors: [],
            data: []
        },
        {
            name: '',
            states: {
                inactive: {
                    opacity: 1
                }
            },
            showInLegend: false,
            colorByPoint: true,
            colors: [],
            data: []
        },
        {
            name: '',
            states: {
                inactive: {
                    opacity: 1
                }
            },
            showInLegend: false,
            colors: [],
            colorByPoint: true,
            data: []
        }
    ]
};
