<highcharts-chart
    *ngIf="options && !isLoading"
    [Highcharts]="Highcharts"
    [options]="options"
    [(update)]="updateFlag"
    [callbackFunction]="chartCallback"
    [ngStyle]="{
        width: '100%',
        display: 'block'
    }"
>
</highcharts-chart>

<div
    *ngIf="isLoading"
    class="loading-container"
    [ngStyle]="{ height: options?.chart?.height || '200px' }"
>
    <div *ngIf="isLoading" class="loading">
        <div
            *ngFor="let item of [].constructor(loadingBars); let i = index"
            class="loading-{{ i + 1 }}"
        ></div>
    </div>
</div>
