export const ICON_LITERALS: Array<IconType> = [
    {
        name: 'phone',
        icon: `<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.44023 7.36538L8.14559 1.66538C8.84202 0.968952 9.77416 0.577881 10.7599 0.577881C11.7456 0.577881 12.6777 0.963595 13.3742 1.66538L19.5188 7.79931C20.2152 8.49574 20.6063 9.43324 20.6063 10.419C20.6063 11.41 20.2206 12.3368 19.5188 13.0386L14.7188 17.844C15.8123 20.3917 17.3834 22.7066 19.3474 24.6636C21.3134 26.6404 23.6117 28.1993 26.1617 29.3029L30.9617 24.4975C31.6581 23.8011 32.5902 23.41 33.5759 23.41C34.0621 23.4082 34.5438 23.5035 34.9927 23.6902C35.4416 23.877 35.8488 24.1514 36.1902 24.4975L42.3402 30.6315C43.0367 31.3279 43.4277 32.2654 43.4277 33.2511C43.4277 34.2422 43.042 35.169 42.3402 35.8707L36.6402 41.5707C35.4509 42.76 33.8117 43.4404 32.1295 43.4404C31.7813 43.4404 31.4438 43.4136 31.1009 43.3547C24.0081 42.1868 16.9688 38.41 11.2849 32.7315C5.6063 27.0422 1.83487 20.0029 0.6563 12.9047C0.318802 10.8904 0.993805 8.81717 2.44023 7.36538ZM4.45452 12.2672C5.49916 18.5832 8.89559 24.8832 14.0117 29.9993C19.1277 35.1154 25.4224 38.5118 31.7384 39.5565C32.5313 39.6904 33.3456 39.4225 33.9242 38.8493L39.5224 33.2511L33.5867 27.31L27.1688 33.7386L27.1206 33.7868L25.9634 33.3582C22.4549 32.0683 19.2688 30.0308 16.626 27.3871C13.9832 24.7433 11.9468 21.5565 10.6581 18.0475L10.2295 16.8904L16.7009 10.4243L10.7652 4.48324L5.16702 10.0815C4.58844 10.66 4.32059 11.4743 4.45452 12.2672Z"/>
        </svg>`
    },
    {
        name: 'thumps-up',
        icon: `<svg width="18" height="18" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
<path d="M16.5117 9.43545C16.8492 8.98947 17.036 8.44304 17.036 7.87451C17.036 6.9725 16.5318 6.11871 15.7202 5.64259C15.5112 5.52004 15.2733 5.45554 15.0311 5.45576H10.2137L10.3342 2.98679C10.3624 2.39014 10.1514 1.82362 9.74161 1.3917C9.54049 1.17881 9.29784 1.00943 9.02867 0.894019C8.7595 0.77861 8.46952 0.719628 8.17665 0.720718C7.13201 0.720718 6.2079 1.42384 5.93067 2.43032L4.205 8.67808H1.60746C1.25188 8.67808 0.9646 8.96536 0.9646 9.32094V16.6334C0.9646 16.989 1.25188 17.2763 1.60746 17.2763H13.6871C13.872 17.2763 14.0528 17.2401 14.2195 17.1678C15.1758 16.76 15.7925 15.8259 15.7925 14.7892C15.7925 14.5361 15.7563 14.287 15.684 14.0459C16.0215 13.6 16.2084 13.0535 16.2084 12.485C16.2084 12.2319 16.1722 11.9828 16.0999 11.7417C16.4374 11.2957 16.6242 10.7493 16.6242 10.1808C16.6202 9.92764 16.584 9.67652 16.5117 9.43545ZM2.41103 15.8299V10.1245H4.03826V15.8299H2.41103ZM15.1959 8.73835L14.7559 9.12005L15.0351 9.63032C15.1271 9.7984 15.1748 9.98714 15.1738 10.1788C15.1738 10.5102 15.0291 10.8256 14.78 11.0426L14.34 11.4243L14.6193 11.9346C14.7113 12.1026 14.759 12.2914 14.7579 12.483C14.7579 12.8145 14.6133 13.1299 14.3642 13.3468L13.9242 13.7285L14.2034 14.2388C14.2954 14.4069 14.3431 14.5956 14.3421 14.7872C14.3421 15.2372 14.0769 15.643 13.6671 15.8279H5.32398V10.0602L7.32286 2.81804C7.3744 2.63242 7.48505 2.46865 7.63803 2.35156C7.79101 2.23447 7.97798 2.17043 8.17063 2.16916C8.32331 2.16916 8.47398 2.21335 8.59451 2.30375C8.79339 2.45241 8.89987 2.67741 8.88781 2.91648L8.69496 6.90219H15.011C15.3686 7.12116 15.5896 7.49081 15.5896 7.87451C15.5896 8.20599 15.445 8.51938 15.1959 8.73835Z" fill=""/>
</svg>`
    },
    {
        name: 'thumps-down',
        icon: `<svg width="18" height="18" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
<path d="M16.5117 8.56823C16.584 8.32715 16.6202 8.07805 16.6202 7.82492C16.6202 7.2564 16.4334 6.70997 16.0959 6.26398C16.1682 6.02291 16.2043 5.77381 16.2043 5.52068C16.2043 4.95215 16.0175 4.40573 15.68 3.95974C15.7523 3.71867 15.7885 3.46957 15.7885 3.21644C15.7885 2.17983 15.1717 1.24568 14.2155 0.837869C14.0474 0.765404 13.8662 0.72847 13.6831 0.729387H1.60746C1.25188 0.729387 0.9646 1.01666 0.9646 1.37224V8.68474C0.9646 9.04032 1.25188 9.3276 1.60746 9.3276H4.205L5.92866 15.5714C6.20589 16.5778 7.13 17.2809 8.17464 17.2809C8.7713 17.2809 9.32777 17.0439 9.7396 16.61C10.1514 16.178 10.3624 15.6115 10.3322 15.0149L10.2117 12.5459H15.0311C15.2742 12.5459 15.5113 12.4816 15.7202 12.3591C16.5318 11.887 17.036 11.0312 17.036 10.1292C17.036 9.56064 16.8492 9.01421 16.5117 8.56823ZM2.41103 7.87916V2.17381H4.03826V7.87916H2.41103ZM15.011 11.1015H8.69496L8.88781 15.0872C8.89987 15.3263 8.79339 15.5513 8.59451 15.6999C8.47197 15.7903 8.3213 15.8365 8.17063 15.8345C7.97814 15.8327 7.79144 15.7684 7.63858 15.6514C7.48572 15.5344 7.37493 15.371 7.32286 15.1856L5.32398 7.94345V2.17381H13.6691C13.8699 2.2638 14.0404 2.40989 14.1601 2.5945C14.2799 2.77911 14.3438 2.99438 14.3441 3.21443C14.3441 3.4093 14.2979 3.59412 14.2054 3.76287L13.9262 4.27314L14.3662 4.65483C14.49 4.76208 14.5893 4.89476 14.6572 5.04383C14.7252 5.19289 14.7602 5.35485 14.7599 5.51867C14.7599 5.71354 14.7137 5.89836 14.6213 6.06711L14.3421 6.57738L14.782 6.95907C14.9058 7.06633 15.0051 7.199 15.0731 7.34807C15.141 7.49713 15.176 7.65909 15.1758 7.82291C15.1758 8.01778 15.1296 8.2026 15.0371 8.37135L14.7559 8.88363L15.1959 9.26532C15.3197 9.37258 15.419 9.50525 15.4869 9.65432C15.5548 9.80338 15.5899 9.96534 15.5896 10.1292C15.5896 10.5129 15.3686 10.8825 15.011 11.1015Z" fill=""/>
  </svg>`
    },
    {
        name: 'doctor',
        icon: `<svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8963 13.078V14.8954C14.3546 15.0768 14.8638 15.4269 15.4558 15.968C15.9142 16.3881 16.1783 16.9833 16.1783 17.6071V19.2431C16.1783 19.6282 15.8664 19.9433 15.4781 19.9433H14.7174C14.3323 19.9433 14.0204 19.6314 14.0204 19.2431C14.0204 18.8579 14.3323 18.546 14.7174 18.546H14.7811V17.6071C14.7811 17.378 14.6824 17.1552 14.5137 16.9992C13.6257 16.1876 13.2502 16.1399 13.1833 16.1399C13.0115 16.1303 12.5595 16.3595 11.8752 16.996C11.7097 17.152 11.6142 17.3716 11.6142 17.5976V18.546H11.6779C12.063 18.546 12.3749 18.8579 12.3749 19.2431C12.3749 19.6282 12.063 19.9433 11.6779 19.9433H10.9172C10.5321 19.9433 10.2202 19.6314 10.2202 19.2431V17.6007C10.2202 16.9865 10.478 16.3945 10.9268 15.9775C11.5219 15.4237 12.0375 15.0704 12.5022 14.8922V12.4606C11.5729 12.1391 10.5798 11.9609 9.54225 11.9609C8.39645 11.9609 7.30158 12.1773 6.29264 12.5656V16.4549C6.7446 16.7032 7.05332 17.1774 7.05332 17.7281C7.05332 18.5333 6.39767 19.1889 5.59562 19.1889C4.79356 19.1889 4.13791 18.5333 4.13791 17.7281C4.13791 17.1774 4.44664 16.7032 4.89859 16.4549V13.2435C2.26007 14.8254 0.5 17.7058 0.5 20.9999H18.5781C18.5781 17.588 16.6876 14.6185 13.8963 13.078Z" fill="#87848D"/>
<path d="M14.7778 -6.10352e-05V5.35336C14.7778 8.31015 12.3811 10.7068 9.42434 10.7068C6.46755 10.7068 4.07092 8.31015 4.07092 5.35336V-6.10352e-05H14.7778ZM12.9922 5.35336H5.85327C5.85327 7.32668 7.45103 8.92124 9.42116 8.92124C11.3913 8.92124 12.9922 7.32349 12.9922 5.35336Z" fill="#87848D"/>
</svg>`
    },
    {
        name: 'progress-average',
        icon: `<svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="4.44571" y1="0.253967" x2="4.44571" y2="15.7213" stroke="#454250" stroke-width="2"/>
<rect x="1.37366" y="5.3941" width="6.06" height="5.78238" fill="white" stroke="#454250"/>
</svg>
`
    },
    {
        name: 'bulb',
        icon: `<svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1241_100075)">
<path d="M15.3994 23.3125H9.77441C9.67129 23.3125 9.58691 23.3969 9.58691 23.5V24.25C9.58691 24.6648 9.92207 25 10.3369 25H14.8369C15.2518 25 15.5869 24.6648 15.5869 24.25V23.5C15.5869 23.3969 15.5025 23.3125 15.3994 23.3125ZM12.5869 4C8.34238 4 4.89941 7.44297 4.89941 11.6875C4.89941 14.5328 6.44629 17.0172 8.74316 18.3461V21.0625C8.74316 21.4773 9.07832 21.8125 9.49316 21.8125H15.6807C16.0955 21.8125 16.4307 21.4773 16.4307 21.0625V18.3461C18.7275 17.0172 20.2744 14.5328 20.2744 11.6875C20.2744 7.44297 16.8314 4 12.5869 4ZM15.5846 16.8859L14.7432 17.3734V20.125H10.4307V17.3734L9.58926 16.8859C7.74473 15.8195 6.58691 13.8555 6.58691 11.6875C6.58691 8.37344 9.27285 5.6875 12.5869 5.6875C15.901 5.6875 18.5869 8.37344 18.5869 11.6875C18.5869 13.8555 17.4291 15.8195 15.5846 16.8859Z" fill="#FF9836"/>
</g>
<path d="M12.5869 5.6875C9.27285 5.6875 6.58691 8.37344 6.58691 11.6875C6.58691 13.8555 7.74473 15.8195 9.58926 16.8859L10.4307 17.3734V20.125H14.7432V17.3734L15.5846 16.8859C17.4291 15.8195 18.5869 13.8555 18.5869 11.6875C18.5869 8.37344 15.901 5.6875 12.5869 5.6875Z" fill="white"/>
<defs>
<filter id="filter0_d_1241_100075" x="0.899414" y="0" width="23.375" height="29" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.596078 0 0 0 0 0.211765 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1241_100075"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1241_100075" result="shape"/>
</filter>
</defs>
</svg>`
    },
    {
        name: 'pencil',
        icon: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.675 5.1435L12.8565 8.32575L5.4315 15.75H2.25V12.5678L9.675 5.14275V5.1435ZM10.7355 4.083L12.3263 2.4915C12.4669 2.3509 12.6576 2.27191 12.8565 2.27191C13.0554 2.27191 13.2461 2.3509 13.3868 2.4915L15.5085 4.61325C15.6491 4.7539 15.7281 4.94463 15.7281 5.1435C15.7281 5.34237 15.6491 5.53311 15.5085 5.67375L13.917 7.2645L10.7355 4.083Z"/>
</svg>`
    },
    {
        name: 'score-card',
        icon: `<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 2H10.75V0.5H3.25V2H1C0.805694 2.01393 0.622917 2.09742 0.48517 2.23517C0.347422 2.37292 0.263931 2.55569 0.25 2.75V14.75C0.263931 14.9443 0.347422 15.1271 0.48517 15.2648C0.622917 15.4026 0.805694 15.4861 1 15.5H13C13.1943 15.4861 13.3771 15.4026 13.5148 15.2648C13.6526 15.1271 13.7361 14.9443 13.75 14.75V2.75C13.7361 2.55569 13.6526 2.37292 13.5148 2.23517C13.3771 2.09742 13.1943 2.01393 13 2ZM4.75 2H9.25V3.5H4.75V2ZM12.25 14H1.75V3.5H3.25V5H10.75V3.5H12.25V14Z"/>
<path d="M8.725 8.375L7.225 9.95L5.875 8.6L3.25 11.225L4.075 12.125L5.875 10.325L7.225 11.675L9.625 9.275L10.75 10.4V7.25H7.6L8.725 8.375Z"/>
</svg>
`
    },
    {
        name: 'square',
        icon: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="14" height="14" fill="#027AFF"/>
</svg>
`
    }
];

interface IconType {
    name: string;
    icon: string;
}
