import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form.component';
import {
    RDIconModule,
    RDInputModule,
    RDSelectModule,
    RDTooltipModule
} from '@zs-ca/angular-cd-library';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [DynamicFormComponent],
    imports: [
        CommonModule,
        RDInputModule,
        RDSelectModule,
        RDIconModule,
        TranslateModule,
        RDTooltipModule
    ],
    exports: [DynamicFormComponent]
})
export class DynamicFormModule {}
