<div *ngFor="let section of formColumns">
    <label *ngIf="section?.label" class="section-label">{{
        section?.label
    }}</label>
    <div class="each-section">
        <div
            *ngFor="let field of section.fields"
            [ngStyle]="{ width: field.width }"
            class="each-field"
        >
            <label
                [ngClass]="{ mandatory: field.isMandatory }"
                class="field-label"
            >
                {{ field.fieldLabel }}
                <rd-tooltip
                    *ngIf="field.tooltip"
                    [tooltipText]="field.tooltip"
                    class="info-icon"
                ></rd-tooltip>
            </label>
            <div
                *ngIf="
                    field.fieldType === 'text' || field.fieldType === 'textArea'
                "
            >
                <rd-input
                    (modelChangeEvent)="
                        field.value = $event; validateForm.emit(field)
                    "
                    [autosize]="{ minRows: 3, maxRows: 6 }"
                    [currentValue]="field.value"
                    [customStyle]="
                        field.fieldType === 'textArea' ? '' : inputStyle
                    "
                    [isDisabled]="field.disabled"
                    [isTextArea]="field.fieldType === 'textArea'"
                    [maxLength]="field.maxLength"
                    [placeholder]="field.placeHolder"
                    size="large"
                >
                </rd-input>
            </div>
            <div *ngIf="field.fieldType === 'select'">
                <rd-select
                    (selectedItemsChange)="
                        field.value = $event; validateForm.emit(field)
                    "
                    selectSize="large"
                    [isKeyLabelOptions]="true"
                    [isLoading]="field.loading"
                    [allowClear]="!field.isMandatory"
                    [mode]="'default'"
                    [options]="field.options"
                    [placeholder]="field.placeHolder"
                    [selectStyle]="selectStyle"
                    [selectedItems]="field.value"
                    [showSearchBox]="field?.searchable"
                    [isDisabled]="field.loading"
                >
                </rd-select>
            </div>
        </div>
    </div>
</div>
