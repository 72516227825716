import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsightsTableComponent } from './insights-table.component';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { RDTooltipModule, RDIconModule } from '@zs-ca/angular-cd-library';
@NgModule({
    declarations: [InsightsTableComponent],
    imports: [CommonModule, RDTooltipModule, RDIconModule, ProgressBarModule],
    exports: [InsightsTableComponent]
})
export class InsightsTableModule {}
