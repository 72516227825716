import { Component, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { RDNotificationService } from '@zs-ca/angular-cd-library';
import { Subscription } from 'rxjs';
import { ToasterService } from './toaster.service';
import { TOASTER_DURATION } from '../../constants/applications-constants';

@Component({
    selector: 'app-toaster',
    templateUrl: './toaster.component.html',
    styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnDestroy {
    @ViewChild('successNotification', { static: false })
    successNotification!: TemplateRef<any>;
    @ViewChild('errorNotification', { static: false })
    errorNotification!: TemplateRef<any>;
    @ViewChild('warningNotification', { static: false })
    warningNotification!: TemplateRef<any>;
    toastSub: Subscription;

    constructor(
        private toasterService: ToasterService,
        private notification: RDNotificationService
    ) {
        this.toastSub = this.toasterService
            .listenForToast()
            .subscribe((toast) => {
                if (toast.type === 'success') {
                    this.notification.template(this.successNotification, {
                        data: {
                            message: toast.message
                        },
                        style: {
                            background: '#F3FCF4',
                            border: '1px solid #45C03D'
                        },
                        duration: TOASTER_DURATION
                    });
                } else if (toast.type === 'error') {
                    this.notification.template(this.errorNotification, {
                        data: {
                            message: toast.message
                        },
                        style: {
                            background: '#fff1f0',
                            border: '1px solid #ffa39e'
                        },
                        duration: TOASTER_DURATION
                    });
                } else if (toast.type === 'warning') {
                    this.notification.template(this.errorNotification, {
                        data: {
                            message: toast.message
                        },
                        style: {
                            background: '#fffd7e6',
                            border: '1px solid #fffd591'
                        },
                        duration: TOASTER_DURATION
                    });
                }
            });
    }

    ngOnDestroy() {
        this.toastSub.unsubscribe();
    }
}
