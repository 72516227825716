import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MyCallsRoutingModule } from './my-calls-routing.module';
import { MyCallsComponent } from './my-calls.component';
import {
    RDButtonModule,
    RDCheckboxModule,
    RDIconModule,
    RDModalModule,
    RDNotificationModule,
    RDInputModule,
    RDTableModule,
    RDBreadcrumbModule,
    RDTooltipModule,
    RDTileModule,
    RDRadioModule,
    RDSpinModule,
    RDDividerModule
} from '@zs-ca/angular-cd-library';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicFormModule } from '../../shared/components/dynamic-form/dynamic-form.module';
import { FeedbackFormModule } from '../../shared/components/feedback-form/feedback-form.module';
import { AudioPlayerModule } from '../../shared/components/audio-player/audio-player.module';
import { DragAndDropModule } from '../../shared/directives/dnd.directive';
import { FormsModule } from '@angular/forms';
import { AddNewCallComponent } from './add-new-call/add-new-call.component';
import { MyCallsListComponent } from './my-calls-list/my-calls-list.component';
import { HighChartsModule } from '../../shared/components/high-charts/high-charts.module';
import { ProgressBarModule } from '../../shared/components/progress-bar/progress-bar.module';
import { InsightsTableModule } from '../../shared/components/insights-table/insights-table.module';
import { ScoreCardComponent } from './score-card/score-card.component';
@NgModule({
    declarations: [
        MyCallsComponent,
        AddNewCallComponent,
        MyCallsListComponent,
        ScoreCardComponent
    ],
    imports: [
        CommonModule,
        MyCallsRoutingModule,
        RDButtonModule,
        RDIconModule,
        RDModalModule,
        RDCheckboxModule,
        TranslateModule,
        DynamicFormModule,
        AudioPlayerModule,
        DragAndDropModule,
        FormsModule,
        RDNotificationModule,
        RDInputModule,
        RDTableModule,
        RDModalModule,
        HighChartsModule,
        RDBreadcrumbModule,
        RDTooltipModule,
        RDTileModule,
        ProgressBarModule,
        InsightsTableModule,
        RDRadioModule,
        FeedbackFormModule,
        RDSpinModule,
        RDDividerModule
    ],
    providers: []
})
export class MyCallsModule {}
