import { Component, OnInit } from '@angular/core';
import { TRANSLATE_KEYS } from '../../shared/constants/translate.keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    translateKeys = TRANSLATE_KEYS;

    languages = TRANSLATE_KEYS.languages;

    currentLanguage = TRANSLATE_KEYS.defaultLanguage;

    constructor(public translateService: TranslateService) {
        translateService.addLangs(
            this.translateKeys.languages.map((i) => i.value)
        );
        translateService.setDefaultLang(this.currentLanguage);
    }

    changeLanguage(language: any) {
        this.translateService.use(language);
    }
}
