export const CONFIG = {
    addCallForm: [
        {
            label: '',
            fields: [
                {
                    fieldLabel: 'Call Title',
                    fieldType: 'text',
                    key: 'callTitle',
                    value: '',
                    placeHolder: 'Enter Call Title here',
                    isMandatory: false,
                    disabled: true,
                    width: '100%',
                    maxLength: 50
                }
            ]
        },
        {
            label: 'HCP Details',
            fields: [
                {
                    fieldLabel: 'Specialty',
                    fieldType: 'select',
                    key: 'specialty',
                    placeHolder: 'Select Specialty',
                    isMandatory: true,
                    sortOptions: true,
                    width: '48%',
                    options: []
                }
            ]
        },
        {
            label: 'HCP Segmentation',
            fields: [
                {
                    fieldLabel: 'ABCD Segment',
                    fieldType: 'select',
                    key: 'segment',
                    placeHolder: 'Select Segment',
                    isMandatory: true,
                    sortOptions: true,
                    width: '48%',
                    options: []
                }
            ]
        },
        {
            label: 'HCP Geography',
            fields: [
                {
                    fieldLabel: 'County',
                    fieldType: 'select',
                    key: 'county',
                    placeHolder: 'Select County',
                    searchable: true,
                    sortOptions: true,
                    width: '48%',
                    options: [],
                    cascadedTo: ['city']
                },
                {
                    fieldLabel: 'City',
                    fieldType: 'select',
                    key: 'city',
                    placeHolder: 'Select City',
                    sortOptions: true,
                    searchable: true,
                    width: '48%',
                    options: [],
                    cascadedFrom: ['county']
                }
            ]
        },
        {
            label: '',
            fields: [
                {
                    fieldLabel: 'Additional Comments',
                    fieldType: 'textArea',
                    key: 'comments',
                    placeHolder: 'Enter Comments',
                    isMandatory: false,
                    showInfo: false,
                    width: '100%'
                }
            ]
        }
    ],
    country: 'UK',
    showScoreCard: true
};
