<rd-modal
    (modalCloseEvent)="closeModal()"
    *ngIf="showAddModal"
    [isCloseable]="true"
    [isMaskClosable]="true"
    [isVisible]="showAddModal"
    [modalDialogClassName]="'new-call'"
    [modalWidth]="'760px'"
    [showMask]="true"
    [isMaskClosable]="false"
>
    <div rdModalTitle>
        <span class="modal-title">Share Feedback On {{ feedbackType }}</span>
    </div>
    <div rdModalContent>
        <div #formParent class="scroll-body">
            <div class="content-text">
                {{ feedbackContentText }}
            </div>
            <div class="radio-button-container">
                <rd-radio-group
                    type="group"
                    [items]="itemList"
                    [selected]="feedbackSentiment"
                    (selectedChange)="radioButtonClicked($event)"
                ></rd-radio-group>
            </div>
            <div class="feedback-input">
                <span class="feedback-text">Feedback</span>
                <rd-input
                    class="feedback-text-area"
                    [isTextArea]="true"
                    [maxLength]="100"
                    [maxCharacterCount]="100"
                    [currentValue]="feedback"
                    [showMaxCharacterCount]="true"
                    placeholder="Enter your feedback here"
                    (modelChangeEvent)="feedbackChangeEvent($event)"
                ></rd-input>
            </div>
        </div>
    </div>
    <div rdModalFooter>
        <rd-button
            (btnClick)="closeModal()"
            [btnText]="translateKeys.common.cancel | translate"
            btnType="default"
        >
        </rd-button>
        <rd-button
            (btnClick)="feedbackSave()"
            [btnText]="translateKeys.common.save | translate"
            [isDisabled]="disableSave()"
            [isLoading]="saveLoading === 'LOADING'"
            btnType="primary"
            class="save-btn"
        >
        </rd-button>
    </div>
</rd-modal>
