import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
    addCommonHttpOptions(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            withCredentials: true
        });
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        /**
         * Event after request execution.
         */
        const customRequest = this.addCommonHttpOptions(request);
        return next.handle(customRequest).pipe(
            tap(
                () => {},
                (error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        if (environment.client !== 'local') {
                            window.open(
                                environment.IDM_LOGIN_URL + window.origin,
                                '_self'
                            );
                        }
                    }
                }
            )
        );
    }
}
