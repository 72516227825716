import { Component, OnInit, OnDestroy } from '@angular/core';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router
} from '@angular/router';
import { RDIconService } from '@zs-ca/angular-cd-library';
import { ICON_LITERALS } from 'src/app/shared/constants/icon-literals.constants';
import { HomeService } from './home.service';
import {
    LOADER,
    SOMETHING_WENT_WRONG,
    UNAUTHORIZED
} from '../shared/constants/applications-constants';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    userInfoLoading: LOADER = 'LOADING';
    userInfo: any;

    launchBarData: any;

    somethingWentWrong = SOMETHING_WENT_WRONG;
    unAuthorizedMessage = UNAUTHORIZED;

    unAuthorized = false;

    loading = false;
    routerSubs: Subscription;

    constructor(
        private rdIcon: RDIconService,
        private homeService: HomeService,
        private router: Router
    ) {
        this.routerSubs = this.router.events.subscribe((e) => {
            this.navigationInterceptor(e);
        });
    }

    ngOnInit(): void {
        this.addCustomIconsToRadius();
        this.getAppConfig();
        this.getUserInfo();
        this.getLaunchBar();
    }

    addCustomIconsToRadius() {
        ICON_LITERALS.map((icon) => {
            this.rdIcon.addIconLiteral('custom:' + icon.name, icon.icon);
        });
    }

    getAppConfig() {
        this.userInfoLoading = 'LOADING';
        this.homeService.fetchAppConfig().subscribe(
            (res) => {
                this.userInfoLoading = 'SUCCESS';
                res['countryId'] = res.drugs[0]['countryId'];
                res['drugId'] = res.drugs[0]['drugId'];
                res['drugName'] = res.drugs[0]['drugName'];
                this.homeService.setAppConfig(res);
            },
            (error) => {
                this.userInfoLoading = 'FAILED';
                this.unAuthorized = error.status === 401;
            }
        );
    }

    getUserInfo() {
        this.homeService.userInfo().subscribe(
            (res) => {
                this.userInfo = res;
            },
            () => {}
        );
    }

    getLaunchBar() {
        this.homeService.launchBar().subscribe(
            (res) => {
                this.launchBarData = res;
            },
            (error) => {
                console.log(error);
            }
        );
    }

    navigationInterceptor(event: any): void {
        if (event instanceof NavigationStart) {
            this.showLoader();
        }
        if (event instanceof NavigationEnd) {
            this.hideLoader();
        }
        if (event instanceof NavigationCancel) {
            this.hideLoader();
        }
        if (event instanceof NavigationError) {
            this.hideLoader();
        }
    }

    private showLoader() {
        this.loading = true;
        document.body.style.overflow = 'hidden';
    }

    private hideLoader() {
        this.loading = false;
        document.body.style.overflow = 'auto';
    }

    ngOnDestroy() {
        this.routerSubs?.unsubscribe();
    }
}
