import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LOADER } from '../../../shared/constants/applications-constants';
import { TRANSLATE_KEYS } from 'src/app/shared/constants/translate.keys';

@Component({
    selector: 'app-feedback-form',
    templateUrl: './feedback-form.component.html',
    styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent {
    translateKeys = TRANSLATE_KEYS;
    @Input() showAddModal = false;
    @Input() feedbackType = '';
    @Input() feedbackContentText = '';
    @Input() feedbackSentiment = 'true';
    @Input() feedbackSessionName = '';
    @Input() itemList: any = [];
    @Input() saveLoading: LOADER = 'LOADING';
    @Output() modalClosed = new EventEmitter<any>();
    @Output() feedbackSaved = new EventEmitter<any>();
    feedback: any;

    constructor() {}

    radioButtonClicked(event: any) {
        this.feedbackSentiment = event;
    }

    feedbackChangeEvent(event: any) {
        this.feedback = event.length > 100 ? event.slice(0, 99) : event;
    }

    closeModal() {
        this.feedback = '';
        this.showAddModal = false;
        this.modalClosed.emit(true);
    }
    feedbackSave() {
        this.saveLoading = 'LOADING';
        this.feedbackSaved.emit({
            feedback: this.feedback,
            feedbackSentiment: this.feedbackSentiment
        });
    }

    disableSave() {
        return this.feedback?.length ? false : true;
    }
}
