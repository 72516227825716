import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-insights-table',
    templateUrl: './insights-table.component.html',
    styleUrls: ['./insights-table.component.scss']
})
export class InsightsTableComponent {
    @Input() data: any = [];
    @Input() hideAverageDetails: boolean = false;
    @Input() isLoading: boolean = false;
    @Input() loadingRows: number = 5;
    @Input() isBestPractice: boolean = false;

    headersConfig: any = [
        [
            {
                label: 'Key Message',
                rowSpan: 2,
                style: {
                    width: '30%',
                    borderBottom: '1px solid #9C9AA1',
                    textAlign: 'left',
                    verticalAlign: 'bottom',
                    color: '#454250',
                    fontSize: '16px',
                    fontWeight: 600
                }
            },
            {
                label: 'Informed HCP',
                colSpan: 2,
                style: {
                    width: '35%',
                    borderLeft: '1px solid #E9E8E9',
                    paddingLeft: '10px',
                    textAlign: 'left',
                    color: '#454250',
                    fontSize: '16px',
                    fontWeight: 600
                }
            }
            // {
            //     label: 'Triggered Discussion',
            //     colSpan: 2,
            //     style: {
            //         width: '35%',
            //         borderLeft: '1px solid #E9E8E9',
            //         paddingLeft: '10px',
            //         textAlign: 'left',
            //         color: '#454250',
            //         fontSize: '16px',
            //         fontWeight: 600
            //     }
            // }
        ],
        [
            {
                label: 'This Call',
                style: {
                    borderBottom: '1px solid #9C9AA1',
                    borderLeft: '1px solid #E9E8E9',
                    paddingLeft: '10px',
                    textAlign: 'left',
                    color: '#454250',
                    fontSize: '14px',
                    fontWeight: 400,
                    width: '10%'
                }
            },
            {
                label: 'Average',
                tooltip:
                    'Proportion of calls where the respective key message was conveyed',
                style: {
                    borderBottom: '1px solid #9C9AA1',
                    textAlign: 'left',
                    color: '#454250',
                    fontSize: '14px',
                    fontWeight: 400,
                    width: '25%'
                }
            }
            // {
            //     label: 'This Call',
            //     style: {
            //         borderBottom: '1px solid #9C9AA1',
            //         borderLeft: '1px solid #E9E8E9',
            //         paddingLeft: '10px',
            //         textAlign: 'left',
            //         color: '#454250',
            //         fontSize: '14px',
            //         fontWeight: 400,
            //         width: '10%'
            //     }
            // },
            // {
            //     label: 'Average',
            //     tooltip:
            //         'Average number of calls where the respective key message was conveyed',
            //     style: {
            //         borderBottom: '1px solid #9C9AA1',
            //         textAlign: 'left',
            //         color: '#454250',
            //         fontSize: '14px',
            //         fontWeight: 400,
            //         width: '25%'
            //     }
            // }
        ]
    ];

    bodyHeaders: any = [
        {
            key: 'keyMessage',
            type: 'text',
            style: {
                color: '#454250',
                fontSize: '16px',
                fontWeight: 400
            }
        },
        {
            key: 'isInformed',
            type: 'icon',
            style: {
                fontSize: '18px',
                borderLeft: '1px solid #E9E8E9'
            }
        },
        {
            key: 'isInformedAvg',
            averageKey: 'informedSegmentAvg',
            insightKey: 'informedInsight',
            type: 'progressBar',
            style: {}
        }
        // {
        //     key: 'isTriggered',
        //     type: 'icon',
        //     style: {
        //         fontSize: '18px',
        //         borderLeft: '1px solid #E9E8E9'
        //     }
        // },
        // {
        //     key: 'isTriggeredAvg',
        //     averageKey: 'triggeredSegmentAvg',
        //     insightKey: 'triggeredInsight',
        //     type: 'progressBar',
        //     style: {}
        // }
    ];
}
