import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(private _http: HttpClient) {}

    private static generateUrl(urlKey: string) {
        if (environment.serverUrl === 'local') {
            return `${'assets/jsons/'}${urlKey}.json`;
        } else {
            return `${environment.serverUrl}/${urlKey}`;
        }
    }

    public get(urlKey: string, params: object = {}): Observable<any> {
        const url = HttpService.generateUrl(urlKey);
        const queryParams = this.convertObjectToQueryParams(params);
        return this._http.get(url, { params: queryParams }).pipe(
            map((response) => {
                return response;
            })
        );
    }

    public delete(urlKey: string, params: object): Observable<any> {
        const url = HttpService.generateUrl(urlKey);
        const queryParams = this.convertObjectToQueryParams(params);
        return this._http.delete(url, { params: queryParams });
    }

    public post(urlKey: string, payload: object = {}): Observable<any> {
        const url = HttpService.generateUrl(urlKey);
        return this._http.post(url, payload).pipe(
            map((response) => {
                return response;
            })
        );
    }

    public patch(urlKey: string, payload: object = {}): Observable<any> {
        const url = HttpService.generateUrl(urlKey);
        return this._http.patch(url, payload).pipe(
            map((response) => {
                return response;
            }),
            catchError((err) => {
                this.errorHandler(err);
                return err;
            })
        );
    }

    public post_s3(url: string, payload: object = {}): Observable<any> {
        return this._http.post(url, payload, { observe: 'response' }).pipe(
            map((response) => {
                return response;
            }),
            catchError((err) => {
                this.errorHandler(err);
                return err;
            })
        );
    }

    errorHandler(e: any) {
        console.log(e);
    }

    private convertObjectToQueryParams(params: any) {
        let queryParams: any = new HttpParams();
        try {
            for (let key in params) {
                if (params.hasOwnProperty(key)) {
                    // @ts-ignore
                    queryParams = queryParams.append(
                        key,
                        params[key].toString()
                    );
                }
            }
        } catch (e: any) {
            this.errorHandler(
                "Couldn't convert params into query params" + e.toString()
            );
        }
        return queryParams;
    }
}
