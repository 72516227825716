import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../../shared/constants/api-endpoint';
import { HttpService } from '../../shared/services/http.service';

@Injectable({
    providedIn: 'root'
})
export class MyCallsService {
    callDetails: any = {};
    constructor(private httpService: HttpService) {}

    fetchDropdowns(payload = {}): Observable<any> {
        return this.httpService.post(API_ENDPOINTS.callDropdowns, payload);
    }

    generateCallTitle(): Observable<any> {
        return this.httpService.get(API_ENDPOINTS.generateCallTitle);
    }

    requestUpload(payload: any): Observable<any> {
        return this.httpService.post(API_ENDPOINTS.requestUpload, payload);
    }

    saveCall(payload: any): Observable<any> {
        return this.httpService.post(API_ENDPOINTS.saveCall, payload);
    }

    uploadAudioToS3(data: any, file: any): Observable<any> {
        const presignedUrl = data['url'];
        const s3Data = new FormData();
        s3Data.append('x-amz-credential', data.fields['x-amz-credential']);
        s3Data.append('key', data.fields.key);
        s3Data.append('policy', data.fields.policy);
        s3Data.append('x-amz-signature', data.fields['x-amz-signature']);
        s3Data.append(
            'x-amz-security-token',
            data.fields['x-amz-security-token']
        );
        s3Data.append('x-amz-algorithm', data.fields['x-amz-algorithm']);
        s3Data.append('x-amz-date', data.fields['x-amz-date']);
        s3Data.append('file', file);

        return this.httpService.post_s3(presignedUrl, s3Data);
    }
    callsGrid(payload: any): Observable<any> {
        return this.httpService.post(API_ENDPOINTS.callsGrid, payload);
    }

    callData(payload: any): Observable<any> {
        return this.httpService.post(API_ENDPOINTS.callData, payload);
    }

    feedback(payload: any): Observable<any> {
        return this.httpService.post(API_ENDPOINTS.feedback, payload);
    }
    editCall(payload: any): Observable<any> {
        return this.httpService.post(API_ENDPOINTS.editCall, payload);
    }
    callAnalysis(payload: any): Observable<any> {
        return this.httpService.post(API_ENDPOINTS.callAnalysis, payload);
    }

    callSummaryInsights(payload: any): Observable<any> {
        return this.httpService.post(
            API_ENDPOINTS.callSummaryInsights,
            payload
        );
    }
    callMessagingInsights(payload: any): Observable<any> {
        return this.httpService.post(
            API_ENDPOINTS.callMessagingInsights,
            payload
        );
    }
    setCallDetails(callDetails: any) {
        this.callDetails = callDetails;
    }
    getCallDetails() {
        return this.callDetails;
    }
}
