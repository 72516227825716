<rd-sider
    (brandClick)="handleBrandClick()"
    (helpClick)="toggleSupportModal(true)"
    (menuItemClick)="menuItemClick($event)"
    [applicationList]="siderData?.applications || []"
    [expirationMessage]="siderData?.expiry"
    [idmAccountUrl]="siderData?.myAccount || ''"
    [logoutUrl]="siderData?.signOut || ''"
    [siderMenuItems]="menuItemsList"
>
    <img alt="LOGO" rd-sider-brand src="assets/images/app-logo.svg" />
</rd-sider>
<rd-modal
    (modalCloseEvent)="toggleSupportModal(false)"
    *ngIf="showHelpModal"
    [isCloseable]="true"
    [isMaskClosable]="true"
    [isVisible]="showHelpModal"
>
    <div rdModalTitle>
        <span class="modal-title">Help Center</span>
    </div>
    <div rdModalFooter>
        <rd-button
            (btnClick)="toggleSupportModal(false)"
            btnText="Close"
            btnType="default"
        ></rd-button>
    </div>
</rd-modal>
