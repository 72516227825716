<div *ngIf="!showAddNewSection" class="mycall-tab">
    <div class="search-box">
        <rd-input [isInputGroup]="true" [groupAddOnAfter]="suffixIconSearch">
            <rd-input
                placeholder="Search By Call Titles"
                [(ngModel)]="searchValue"
                [isBorderless]="false"
                (modelChangeEvent)="onSearch($event)"
                style="width: 100%"
                size="large"
            ></rd-input>
        </rd-input>
        <ng-template #suffixIconSearch>
            <rd-icon [type]="'search'"></rd-icon>
        </ng-template>
    </div>
    <div>
        <rd-button
            (btnClick)="openModal()"
            [btnText]="translateKeys.myCalls.addCall | translate"
            [iconStyle]="{ fontSize: '16px' }"
            btnType="primary"
            class="add-call-btn"
            iconType="plus"
        >
        </rd-button>
    </div>
</div>
<div class="mycall-table" *ngIf="!showAddNewSection">
    <rd-table
        class="raduis-table"
        [tableScrollDetails]="callListData.length ? scrollDetails : {}"
        [isLoading]="callListLoading"
        [tableColumns]="callListTableColumns"
        [tableData]="callListData"
        [showPagination]="true"
        [showSizeChanger]="false"
        [pageIndex]="tableConfig.pageIndex"
        [pageSize]="tableConfig.pageSize"
        [frontEndPagination]="false"
        [totalCount]="tableConfig.totalCount"
        [errorMessage]="'No Records Found'"
        (paginationChange)="handleTablePageChange($event)"
        (sortApply)="sortBy($event)"
        (linkColumnCellClicked)="onTableColumnClick($event)"
        (tableRowClick)="onTableRowClick($event)"
    >
    </rd-table>
</div>
<div class="add-call-section" *ngIf="showAddNewSection">
    <rd-icon class="phone-icon" type="custom:phone"></rd-icon>
    <h3 class="add-call">{{ translateKeys.myCalls.addNewCall | translate }}</h3>
    <h5 class="upload-text">
        {{ translateKeys.myCalls.addNewCallDesc | translate }}
    </h5>
    <rd-button
        (btnClick)="openModal()"
        [btnText]="translateKeys.myCalls.addCall | translate"
        [iconStyle]="{ fontSize: '16px' }"
        btnType="primary"
        class="add-call-btn"
        iconType="plus"
    >
    </rd-button>
</div>
<app-add-new-call
    [showAddModal]="showAddModal"
    [editCallId]="editCallId"
    (modalClosed)="modalClosed($event)"
>
</app-add-new-call>
