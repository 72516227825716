import { Component, Input, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements AfterViewInit {
    @Input() percentage: number = 0;
    @Input() barColor = '#4fcea3';
    @Input() bgColor = '#dedede';
    @Input() average: number = 0;
    @Input() barTooltipText = '';
    @Input() averageTooltipText = '';
    @Input() isLoading = false;
    @Input() showAverage: boolean = true;

    progressStyle = {};

    ngAfterViewInit() {
        this.progressStyle = {
            width: this.percentage + '%',
            background: this.barColor
        };
    }
}
