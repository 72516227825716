import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'app-high-charts',
    templateUrl: './high-charts.component.html',
    styleUrls: ['./high-charts.component.scss']
})
export class HighChartsComponent {
    @Input() options: any;
    @Input() height = '200px';
    @Input() data: any = undefined;
    @Input() isLoading: boolean = false;
    @Input() loadingBars: number = 8;

    chartCallback: any = undefined;
    chart: any = undefined;

    Highcharts = Highcharts;
    updateFlag = false;

    backupColors: any;

    constructor() {
        const self: any = this;
        this.chartCallback = (chart: any) => {
            self.chart = chart;
            chart.chartComponent = self;
        };
    }

    highlight() {
        if (this.backupColors) {
            this.options.series[2].colors = this.backupColors.hcp;
            this.options.series[3].colors = this.backupColors.rep;
            this.backupColors = undefined;
        } else {
            this.backupColors = {
                hcp: [...this.options.series[2].colors],
                rep: [...this.options.series[3].colors]
            };
            const selectedIndexes = [23, 21, 22];
            for (let i = 0; i < this.options.series[0].data.length; i++) {
                if (!selectedIndexes.includes(i)) {
                    this.options.series[2].colors[i] = '#e8e8e8';
                    this.options.series[3].colors[i] = '#e8e8e8';
                }
            }
        }
        this.updateFlag = true;
    }
}
