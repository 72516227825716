<div *ngIf="userInfoLoading === 'SUCCESS'" class="main">
    <div class="left-section">
        <app-side-bar [siderData]="launchBarData"></app-side-bar>
    </div>
    <div class="right-section">
        <app-header></app-header>
        <app-line-loader *ngIf="loading"></app-line-loader>
        <router-outlet></router-outlet>
    </div>
</div>
<div *ngIf="userInfoLoading === 'LOADING'" class="loader-error">
    <rd-spin
        [spinSize]="'large'"
        [spinnerStyle]="{ fontSize: '24px', color: '#27a6a4' }"
    ></rd-spin>
    <div class="text">Loading</div>
</div>
<div *ngIf="userInfoLoading === 'FAILED'" class="loader-error">
    <rd-alert
        *ngIf="!unAuthorized"
        [closable]="false"
        [message]="somethingWentWrong"
        [showIcon]="true"
        type="error"
    ></rd-alert>
    <rd-alert
        *ngIf="unAuthorized"
        [closable]="false"
        [message]="unAuthorizedMessage"
        [showIcon]="true"
        type="warning"
    ></rd-alert>
</div>
<app-toaster></app-toaster>
