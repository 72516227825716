import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterComponent } from './toaster.component';
import { RDIconModule, RDNotificationModule } from '@zs-ca/angular-cd-library';

@NgModule({
    declarations: [ToasterComponent],
    exports: [ToasterComponent],
    imports: [CommonModule, RDIconModule, RDNotificationModule]
})
export class ToasterModule {}
