import { Component } from '@angular/core';
import { TRANSLATE_KEYS } from '../../shared/constants/translate.keys';

@Component({
    selector: 'app-my-calls',
    templateUrl: './my-calls.component.html',
    styleUrls: ['./my-calls.component.scss']
})
export class MyCallsComponent {
    translateKeys = TRANSLATE_KEYS;
    showAddModal = false;
}
