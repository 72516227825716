<ng-template #successNotification let-data="data">
    <div class="align-center">
        <rd-icon
            [iconStyle]="{
                fontSize: '20px',
                'margin-right': '1rem',
                color: '#45C03D'
            }"
            [type]="'check-circle'"
        >
        </rd-icon>
        {{ data?.message }}
    </div>
</ng-template>
<ng-template #errorNotification let-data="data">
    <div class="align-center">
        <rd-icon
            [iconStyle]="{
                fontSize: '20px',
                'margin-right': '1rem',
                color: '#ffa39e'
            }"
            [type]="'warning'"
        >
        </rd-icon>
        {{ data?.message }}
    </div>
</ng-template>
<ng-template #warningNotification let-data="data">
    <div class="align-center">
        <rd-icon
            [iconStyle]="{
                fontSize: '20px',
                'margin-right': '1rem',
                color: '#fffd591'
            }"
            [type]="'exclamation-circle'"
        >
        </rd-icon>
        {{ data?.message }}
    </div>
</ng-template>
