import { CONFIG } from '../app/shared/constants/client-config/dev_uk';

export const environment = {
    production: false,
    serverUrl: 'https://maci-uk.dev.zsservices.com',
    IDM_LOGIN_URL:
        'https://idm.qa.zsservices.com/REVO/IdentityManager/app/Web/Login.aspx?ReturnUrl=',
    client: 'dev',
    clientConfig: CONFIG
};
