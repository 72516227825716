import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighChartsComponent } from './high-charts.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { RDButtonModule } from '@zs-ca/angular-cd-library';

@NgModule({
    declarations: [HighChartsComponent],
    exports: [HighChartsComponent],
    imports: [CommonModule, HighchartsChartModule, RDButtonModule]
})
export class HighChartsModule {}
