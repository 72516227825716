<rd-modal
    (modalCloseEvent)="closeModal()"
    *ngIf="showAddModal"
    [isCloseable]="true"
    [isMaskClosable]="true"
    [isVisible]="showAddModal"
    [modalDialogClassName]="'new-call'"
    [modalWidth]="'760px'"
>
    <div rdModalTitle>
        <span class="modal-title">{{
            editCallId
                ? (translateKeys.newCall.modalCallEditTitle | translate)
                : (translateKeys.newCall.modalTitle | translate)
        }}</span>
    </div>
    <div rdModalContent>
        <div #formParent (scroll)="onModalScroll($event)" class="scroll-body">
            <app-dynamic-form
                (validateForm)="validateForm($event)"
                [formColumns]="formColumns"
            >
            </app-dynamic-form>
            <div class="recording-header">
                {{ translateKeys.newCall.uploadHeader | translate }}
            </div>
            <div
                (click)="audioInput.click()"
                (file)="fileDropped($event)"
                *ngIf="!audioUrl"
                appDnd
                class="upload-section"
            >
                <div class="icon">
                    <img alt="UPLOAD" src="assets/images/file-upload.svg" />
                    <div class="desc">
                        {{ translateKeys.newCall.uploadDesc | translate }}
                    </div>
                    <div class="type">
                        {{ translateKeys.newCall.uploadType | translate }}
                    </div>
                    <!--<div class="size">{{translateKeys.newCall.uploadMaxSize | translate}} </div>-->
                </div>
            </div>
            <input
                #audioInput
                (change)="handleChange($event)"
                [(ngModel)]="audioModel"
                accept=".wav,.m4a"
                class="d-none"
                type="file"
            />
            <app-audio-player
                (deleteClick)="deleteClick()"
                *ngIf="audioUrl && !editCallId"
                [isDeleteEnable]="!editCallId"
                [fileName]="fileName"
                [url]="audioUrl"
            >
            </app-audio-player>
            <app-audio-player
                [fileName]="fileName"
                *ngIf="audioUrl && editCallId"
                [isDeleteEnable]="!editCallId"
                [url]="audioUrl"
            >
            </app-audio-player>
            <rd-checkbox
                [(isChecked)]="AE_HSIConsent"
                [checkBoxStyle]="{
                    marginTop: '20px',
                    fontSize: '16px',
                    color: '#000000d9'
                }"
                [label]="translateKeys.newCall.AE_HSIConsent | translate"
            >
            </rd-checkbox>
            <rd-checkbox
                [(isChecked)]="consent"
                [checkBoxStyle]="{
                    marginTop: '20px',
                    fontSize: '16px',
                    color: '#000000d9'
                }"
                [label]="translateKeys.newCall.consent | translate"
            >
            </rd-checkbox>
        </div>
    </div>
    <div rdModalFooter>
        <rd-button
            (btnClick)="closeModal()"
            [btnText]="translateKeys.common.cancel | translate"
            btnType="default"
        >
        </rd-button>
        <rd-button
            (btnClick)="save()"
            [btnText]="translateKeys.common.save | translate"
            [isDisabled]="disableSave()"
            [isLoading]="saveLoading === 'LOADING'"
            btnType="primary"
            class="save-btn"
        >
        </rd-button>
    </div>
</rd-modal>
