<div *ngIf="!isLoading" class="progress-bar-container">
    <div class="percentage">{{ percentage }}%</div>
    <div class="bar" [ngStyle]="{ background: bgColor }">
        <rd-tooltip [tooltipText]="barTooltipText" tooltipPlacement="topLeft">
            <div
                class="progress"
                [ngStyle]="{
                    width: percentage + '%',
                    background: barColor
                }"
            ></div>
        </rd-tooltip>
        <div
            *ngIf="showAverage"
            class="average"
            [ngStyle]="{ left: 'calc(' + average + '% - 8px)' }"
        >
            <rd-tooltip [tooltipText]="averageTooltipText">
                <rd-icon
                    class="progress-average"
                    type="custom:progress-average"
                >
                </rd-icon>
            </rd-tooltip>
        </div>
    </div>
</div>

<div *ngIf="isLoading" class="progress-loader">
    <div class="percentage skeleton"></div>
    <div class="progress skeleton"></div>
</div>
