<!--<div class="player-container">-->
<!--    <div class="icon">-->
<!--        <rd-icon [type]="playingFlag?'pause-circle':'play-circle'" theme="outline" (click)="playPause()"></rd-icon>-->
<!--    </div>-->
<!--    <div class="player">-->
<!--        <div id="waveform"></div>-->
<!--    </div>-->
<!--</div>-->
<div class="player-container">
    <div class="player">
        <div class="name">
            {{ fileName }}
        </div>
        <audio
            [src]="url"
            controls="true"
            controlsList="noplaybackrate nodownload"
        ></audio>
    </div>
    <div class="delete" *ngIf="isDeleteEnable">
        <rd-icon
            (click)="onDeleteClick()"
            [type]="'delete'"
            theme="outline"
        ></rd-icon>
    </div>
</div>
