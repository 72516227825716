import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { HeaderComponent } from './header/header.component';
import { HomeService } from './home.service';
import {
    RDAlertModule,
    RDButtonModule,
    RDDividerModule,
    RDDropdownModule,
    RDHeaderModule,
    RDIconModule,
    RDModalModule,
    RDSelectModule,
    RDSiderModule,
    RDSpinModule
} from '@zs-ca/angular-cd-library';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ToasterModule } from '../shared/components/toaster/toaster.module';
import { LineLoaderModule } from '../shared/components/line-loader/line-loader.module';

@NgModule({
    declarations: [HomeComponent, SideBarComponent, HeaderComponent],
    imports: [
        CommonModule,
        FormsModule,
        HomeRoutingModule,
        RDSiderModule,
        RDHeaderModule,
        RDButtonModule,
        RDModalModule,
        RDIconModule,
        TranslateModule,
        RDDropdownModule,
        RDSelectModule,
        RDSpinModule,
        RDAlertModule,
        ToasterModule,
        LineLoaderModule,
        RDDividerModule
    ],
    providers: [HomeService]
})
export class HomeModule {}
