import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackFormComponent } from './feedback-form.component';
import {
    RDIconModule,
    RDInputModule,
    RDSelectModule,
    RDTooltipModule,
    RDRadioModule,
    RDButtonModule,
    RDModalModule
} from '@zs-ca/angular-cd-library';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [FeedbackFormComponent],
    imports: [
        CommonModule,
        RDInputModule,
        RDSelectModule,
        RDIconModule,
        TranslateModule,
        RDTooltipModule,
        RDRadioModule,
        RDButtonModule,
        RDModalModule
    ],
    exports: [FeedbackFormComponent]
})
export class FeedbackFormModule {}
