import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../shared/services/http.service';
import { API_ENDPOINTS } from '../shared/constants/api-endpoint';

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    constructor(private httpService: HttpService) {}

    private appConfig: any;

    setAppConfig(config: any) {
        this.appConfig = config;
    }

    getAppConfig() {
        return { ...this.appConfig };
    }

    launchBar(): Observable<any> {
        return this.httpService.get(API_ENDPOINTS.launchBar);
    }

    userInfo(): Observable<any> {
        return this.httpService.get(API_ENDPOINTS.userinfo);
    }

    fetchAppConfig(): Observable<any> {
        return this.httpService.get(API_ENDPOINTS.appConfig);
    }
}
