export type LOADER = 'LOADING' | 'FAILED' | 'SUCCESS';

export const SOMETHING_WENT_WRONG = 'Something went wrong';
export const UNAUTHORIZED = 'You are not authenticated, Please login.';
export const TOASTER_DURATION = 4500;

export const SCORE_CARD_SECTIONS = {
    callObjective: 'Call Objective',
    callAnalysis: 'Call Analysis',
    messagingInsights: 'Messaging Insights'
};
