<div class="breadcrumb">
    <rd-breadcrumb [breadcrumbData]="breadcrumbData"></rd-breadcrumb>
</div>
<div
    style="display: flex; flex-direction: column"
    [ngStyle]="
        scoreCardStatus === 'validated' ? {} : { 'padding-bottom': '2rem' }
    "
>
    <div>
        <div class="main-header-container">
            <h3 class="main-header">
                <span *ngIf="callDataLoading === 'SUCCESS'">
                    {{ callDetails?.callTitle || '-' }}
                </span>
                <div
                    *ngIf="callDataLoading === 'LOADING'"
                    class="skeleton"
                ></div>
            </h3>
        </div>

        <div class="call-details">
            <div *ngIf="callDataLoading === 'SUCCESS'" class="sub-header">
                <span class="sub-headers-title">Created On:</span>
                <span class="sub-headers-value">
                    {{ (callDetails?.createdDate | date : 'd MMM y') || '-' }}
                </span>
            </div>
            <div *ngIf="callDataLoading === 'SUCCESS'" class="sub-header">
                <span class="sub-headers-title">Last Updated On:</span>
                <span class="sub-headers-value">
                    {{ (callDetails?.modifiedDate | date : 'd MMM y') || '-' }}
                </span>
            </div>
            <div *ngIf="callDataLoading === 'LOADING'" class="sub-header">
                <div class="skeleton"></div>
            </div>
            <div *ngIf="callDataLoading === 'LOADING'" class="sub-header">
                <div class="skeleton"></div>
            </div>
        </div>
    </div>
    <div>
        <div class="container">
            <div *ngIf="callDataLoading === 'SUCCESS'" class="container-width">
                <rd-tile
                    class="hpc-container detail-tile"
                    [checked]="false"
                    mode="default"
                >
                    <div class="heading">
                        <rd-icon
                            class="doctor-icon"
                            type="custom:doctor"
                        ></rd-icon>
                        <span class="heading-title"> HCP Details </span>
                        <div class="hpc-details">
                            <div class="hpc-item specialty">
                                <span class="sub-headers-title">Specialty</span>
                                <span class="sub-headers-value">
                                    {{ callDetails?.specialty_name || '-' }}
                                </span>
                            </div>

                            <div
                                *ngFor="let segment of callDetails?.segments"
                                class="hpc-item"
                            >
                                <span class="sub-headers-title">
                                    {{ segment.label }}
                                </span>
                                <span class="sub-headers-value">
                                    {{ segment?.valueLabel || '-' }}
                                </span>
                            </div>
                        </div>
                    </div>
                </rd-tile>
            </div>
            <div *ngIf="callDataLoading === 'LOADING'" class="container-width">
                <rd-tile class="hpc-container" [checked]="false" mode="default">
                    <div class="heading">
                        <div class="header-skeleton">
                            <div class="icon skeleton"></div>
                            <div class="label skeleton"></div>
                        </div>
                        <div class="hpc-details">
                            <div class="hpc-item specialty">
                                <div class="sub-headers-title skeleton"></div>
                                <span class="sub-headers-value skeleton"></span>
                            </div>

                            <div
                                *ngFor="let segment of [1, 2, 3, 4]"
                                class="hpc-item"
                            >
                                <div class="sub-headers-title skeleton"></div>
                                <div class="sub-headers-value skeleton"></div>
                            </div>
                        </div>
                    </div>
                </rd-tile>
            </div>
            <div
                *ngIf="
                    callAnalysisDataLoading === 'SUCCESS' &&
                    scoreCardStatus === 'validated'
                "
                class="container-width"
            >
                <rd-tile
                    class="call-objective-container"
                    [checked]="false"
                    mode="default"
                >
                    <div class="call-objective-item">
                        <div class="call-objective-item-margin">
                            <div class="call-objective-outcome">
                                <div class="call-objective-title">
                                    Call Objective
                                    <rd-tooltip
                                        tooltipText="Good Selling Outcome is defined by customer agreement action planning through either identifying eligible patients, recalling eligible patients, or setting up a dedicated clinic"
                                        class="info-icon"
                                    ></rd-tooltip>
                                </div>

                                <!-- <div>
                                    <rd-tooltip
                                        [tooltipText]="
                                            'Share Feedback On Call Objective'
                                        "
                                        class="insight"
                                    >
                                        <rd-icon
                                            (click)="
                                                openFeedbackPopup(
                                                    sections.callObjective,
                                                    'callObjective',
                                                    'true'
                                                )
                                            "
                                            class="thumps-up-icon"
                                            type="custom:thumps-up"
                                        ></rd-icon>
                                        <rd-icon
                                            (click)="
                                                openFeedbackPopup(
                                                    sections.callObjective,
                                                    'callObjective',
                                                    'false'
                                                )
                                            "
                                            class="thumps-down-icon"
                                            type="custom:thumps-down"
                                        ></rd-icon>
                                    </rd-tooltip>
                                </div> -->
                            </div>

                            <div>
                                <div>
                                    <span class="call-objective-sub-title">{{
                                        callAnalysisDetails?.prescribeMessage
                                            ? callAnalysisDetails?.prescribeMessage
                                            : '-'
                                    }}</span>
                                    <div class="call-objective-item-container">
                                        <div
                                            *ngIf="
                                                callAnalysisDetails?.agreedWithTimeline
                                            "
                                        >
                                            <span class="sub-headers">
                                                <rd-icon
                                                    class="check-circle-icon"
                                                    type="check-circle"
                                                ></rd-icon>
                                                <!-- <rd-icon
                                                    *ngIf="
                                                        !callAnalysisDetails?.agreedWithTimeline
                                                    "
                                                    class="close-circle-icon"
                                                    type="close-circle"
                                                ></rd-icon> -->
                                                Action Planning Agreed with
                                                Timeline by HCP on this
                                                call</span
                                            >
                                        </div>
                                        <div
                                            *ngIf="
                                                callAnalysisDetails?.agreedByHcp
                                            "
                                        >
                                            <span class="sub-headers">
                                                <rd-icon
                                                    class="check-circle-icon"
                                                    type="check-circle"
                                                ></rd-icon>
                                                <!-- <rd-icon
                                                    *ngIf="
                                                        !callAnalysisDetails?.agreedByHcp
                                                    "
                                                    class="close-circle-icon"
                                                    type="close-circle"
                                                ></rd-icon> -->
                                                Action Planning Agreed by HCP on
                                                this call</span
                                            >
                                        </div>
                                        <div
                                            *ngIf="
                                                callAnalysisDetails?.planningDiscussed
                                            "
                                        >
                                            <span class="sub-headers">
                                                <!-- <rd-icon
                                                    *ngIf="
                                                        callAnalysisDetails?.planningDiscussed
                                                    "
                                                    class="check-circle-icon"
                                                    type="check-circle"
                                                ></rd-icon> -->
                                                <rd-icon
                                                    class="clock-circle-icon"
                                                    type="clock-circle"
                                                ></rd-icon
                                                >Action Planning Discussed on
                                                this call</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="progress-bar-container">
                        <div>
                            <!-- <app-progress-bar
                                *ngIf="callAnalysisDetails?.count === 1"
                                [percentage]="0"
                                [average]="0"
                                [showAverage]="false"
                                averageTooltipText=""
                            ></app-progress-bar> -->
                            <app-progress-bar
                                *ngIf="callAnalysisDetails?.count >= 2"
                                [percentage]="callAnalysisDetails?.successAvg"
                                [average]="
                                    callAnalysisDetails?.topPerformerSuccessAvg
                                "
                                averageTooltipText="
                                Avg. of top performing reps: {{
                                    callAnalysisDetails?.topPerformerSuccessAvg
                                        ? callAnalysisDetails?.topPerformerSuccessAvg
                                        : '-'
                                }}%
                            "
                            ></app-progress-bar>
                        </div>
                        <div class="progress-text-container">
                            <div class="icon-container">
                                <div class="square-container">
                                    <div class="square square-green"></div>
                                    <span class="legend-hcp-text"
                                        >Agreement Ratio in your Calls</span
                                    >
                                </div>
                                <div class="legend-average-container">
                                    <rd-icon
                                        class="progress-average-icon"
                                        type="custom:progress-average"
                                    ></rd-icon>
                                    <span class="legend-average-text"
                                        >Avg. of all rep calls​</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </rd-tile>
            </div>
            <div
                *ngIf="
                    callAnalysisDataLoading === 'LOADING' &&
                    scoreCardStatus === 'validated'
                "
                class="container-width"
            >
                <rd-tile
                    class="call-objective-container"
                    [checked]="false"
                    mode="default"
                >
                    <div class="call-objective-item">
                        <div class="call-objective-item-margin">
                            <div class="call-objective-outcome">
                                <div class="call-objective-title">
                                    <div class="skeleton"></div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div
                                        class="call-objective-sub-title skeleton"
                                    ></div>
                                    <div class="call-objective-item-container">
                                        <div>
                                            <div
                                                class="sub-headers skeleton"
                                            ></div>
                                        </div>
                                        <div>
                                            <div
                                                class="sub-headers skeleton"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="progress-bar-container">
                        <div class="header skeleton"></div>
                        <app-progress-bar [isLoading]="true"></app-progress-bar>
                    </div>
                </rd-tile>
            </div>
        </div>
    </div>
    <rd-divider></rd-divider>
    <div class="call-details-container">
        <div class="sub-headers-title">What was said on the call?</div>
        <!-- <div class="call-details-content">
            <div>
                The height of each bar represents the duration of a voice
                recording snippet.
            </div>
            <div>
                <span class="title">Call Duration:</span>
                <span class="value">{{
                    callAnalysisDetails?.callDuration || '-'
                }}</span>
            </div>
        </div> -->
        <div>
            <div class="call-duration-container">
                <!-- <app-high-charts
                    [options]="durationChartOptions"
                    [data]="data"
                    [isLoading]="callSummaryDataLoading === 'LOADING'"
                    [loadingBars]="15"
                ></app-high-charts> -->
                <!-- <div
                                *ngFor="let segment of [1, 2, 3, 4]"
                                class="hpc-item"
                            >
                                <div class="sub-headers-title skeleton"></div>
                                <div class="sub-headers-value skeleton"></div>
                            </div> -->

                <div
                    class="call-summary"
                    *ngIf="callSummaryDataLoading === 'LOADING'"
                >
                    <div class="call-item" *ngFor="let item of [1, 2, 3, 4]">
                        <div
                            class="summary-header skeleton skeleton-header"
                        ></div>
                        <div class="summary-text skeleton skeleton-text"></div>
                    </div>
                </div>
                <div
                    class="call-summary"
                    *ngIf="callSummaryDataLoading === 'SUCCESS'"
                >
                    <div class="call-item" *ngFor="let item of callSummaryData">
                        <div class="summary-header">{{ item?.type }}:</div>
                        <div class="summary-text">{{ item?.insight }}</div>
                    </div>
                </div>
            </div>
            <!-- <div class="call-duration-icons-container">
                <div class="icons-container-border">
                    <div class="legends-container">
                        <div class="concern-square"></div>
                        <span class="legend-hcp-text"> Concern Raised</span>
                    </div>
                    <div class="legends-container">
                        <div class="key-square"></div>
                        <span class="legend-hcp-text">Key Message</span>
                    </div>
                    <div class="legends-container">
                        <div class="questions-square"></div>
                        <span class="legend-hcp-text">Questions</span>
                    </div>
                </div>
                <div class="legends-container">
                    <div class="positve-square"></div>
                    <span class="legend-hcp-text">Positive Sentiment</span>
                </div>
                <div class="legends-container">
                    <div class="negative-square"></div>
                    <span class="legend-hcp-text"> Negative Sentiment</span>
                </div>
            </div> -->
        </div>
    </div>
    <rd-divider></rd-divider>
    <div
        class="note-content note-color-info"
        *ngIf="scoreCardStatus === 'partially completed'"
    >
        <rd-icon class="bulb-icon" type="info-circle" theme="fill"></rd-icon>
        <span class="note-content-text">
            Stay tuned for call insights
            <!-- {{
                (callDetails?.summary_available_date | date : 'dd MMM yyyy') ||
                    '-'
            }} -->
        </span>
    </div>
    <div
        class="call-analysis-container"
        *ngIf="scoreCardStatus === 'validated'"
    >
        <div class="call-analysis-header">
            <div>
                <span class="sub-headers-title"
                    >Call Analysis
                    <rd-tooltip
                        tooltipText="HCP Participation represents the proportion of the call the customer talks versus the rep"
                        class="info-icon"
                    ></rd-tooltip
                ></span>
            </div>
            <!-- <div>
                <rd-tooltip
                    [tooltipText]="'Share Feedback On Call Analysis'"
                    class="insight"
                >
                    <rd-icon
                        (click)="
                            openFeedbackPopup(
                                sections.callAnalysis,
                                'callAnalysis',
                                'true'
                            )
                        "
                        class="thumps-up-icon"
                        type="custom:thumps-up"
                    ></rd-icon>
                    <rd-icon
                        (click)="
                            openFeedbackPopup(
                                sections.callAnalysis,
                                'callAnalysis',
                                'false'
                            )
                        "
                        class="thumps-down-icon"
                        type="custom:thumps-down"
                    ></rd-icon>
                </rd-tooltip>
            </div> -->
        </div>
        <div class="analysis-container">
            <div class="participation-chart">
                <div class="high-chart-header-title">
                    HCP Participation on this Call
                </div>
                <div class="chart-width">
                    <app-high-charts
                        [options]="hcpChartOptions"
                        [isLoading]="callAnalysisDataLoading === 'LOADING'"
                    ></app-high-charts>
                </div>
                <div class="icon-container">
                    <div class="square-container">
                        <div class="square"></div>
                        <span class="legend-hcp-text"> HCP Participation</span>
                    </div>
                    <div class="legend-average-container">
                        <rd-icon
                            class="progress-average-icon"
                            type="custom:progress-average"
                        ></rd-icon>
                        <span class="legend-average-text"
                            >Avg. of all rep calls​</span
                        >
                    </div>
                </div>
            </div>
            <!-- <div class="questions-chart">
                <div class="high-chart-header-title">
                    Questions asked by you
                </div>
                <div class="chart-width">
                    <app-high-charts
                        [options]="repChartOptions"
                        [isLoading]="callAnalysisDataLoading === 'LOADING'"
                    ></app-high-charts>
                </div>

                <div class="icon-container">
                    <div class="square-container">
                        <div class="square"></div>
                        <span class="legend-hcp-text"> HCP participation</span>
                    </div>
                    <div
                        *ngIf="callAnalysisDetails?.count > 1"
                        class="legend-average-container"
                    >
                        <rd-icon
                            class="progress-average-icon"
                            type="custom:progress-average"
                        ></rd-icon>
                        <span class="legend-average-text"
                            >Avg. of all rep calls​</span
                        >
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <rd-divider *ngIf="scoreCardStatus === 'validated'"></rd-divider>
    <div class="msg-container" *ngIf="scoreCardStatus === 'validated'">
        <div class="msg-insights-container">
            <div class="msg-insights-header">
                <div>
                    <span class="sub-headers-title"
                        >Messaging Insights
                        <!-- <rd-tooltip
                            [tooltipText]="
                                'Overview of key messages delivered and recommendations for improvement in calls with HCPs who belong to the same segment'
                            "
                            class="info-icon"
                        ></rd-tooltip> -->
                    </span>
                </div>
                <!-- <div>
                    <rd-tooltip
                        [tooltipText]="'Share Feedback On Messaging Insights'"
                        class="insight"
                    >
                        <rd-icon
                            (click)="
                                openFeedbackPopup(
                                    sections.messagingInsights,
                                    'messagingInsights',
                                    'true'
                                )
                            "
                            class="thumps-up-icon"
                            type="custom:thumps-up"
                        ></rd-icon>
                        <rd-icon
                            (click)="
                                openFeedbackPopup(
                                    sections.messagingInsights,
                                    'messagingInsights',
                                    'false'
                                )
                            "
                            class="thumps-down-icon"
                            type="custom:thumps-down"
                        ></rd-icon>
                    </rd-tooltip>
                </div> -->
                <!-- <div class="icon-container">
                    <div class="segment-average-text">
                        Commercial Segment Average:
                    </div>
                    <div class="square-container">
                        <div class="square square-purple"></div>
                        <span class="legend-avg-call-text">
                            Avg. of your calls</span
                        >
                    </div>
                    <div class="legend-average-container">
                        <rd-icon
                            class="progress-average-icon"
                            type="custom:progress-average"
                        ></rd-icon>
                        <span class="legend-average-text"
                            >Avg. of all rep calls​</span
                        >
                    </div>
                </div> -->
            </div>
        </div>
        <div class="msg-body">
            <div class="message-sub-header">
                <div class="msg-body-section-header">Key Message</div>
                <div
                    class="icon-container"
                    *ngIf="!(messageInsightsKeyMessageData[0]?.count < 2)"
                >
                    <!-- <div class="segment-average-text">
                                Commercial Segment Average:
                            </div> -->
                    <div class="square-container">
                        <div class="square square-purple"></div>
                        <span class="legend-avg-call-text">
                            Avg. of your calls</span
                        >
                    </div>
                    <div class="legend-average-container">
                        <rd-icon
                            class="progress-average-icon"
                            type="custom:progress-average"
                        ></rd-icon>
                        <span class="legend-average-text"
                            >Avg. of all rep calls​</span
                        >
                    </div>
                </div>
            </div>

            <app-insights-table
                [data]="messageInsightsKeyMessageData"
                [hideAverageDetails]="
                    messageInsightsKeyMessageData[0]?.count < 2
                "
                [isLoading]="callMessagingInsightsLoading === 'LOADING'"
            ></app-insights-table>
            <div class="message-sub-header" style="margin-top: 10px">
                <div class="msg-body-section-header">Best Practices</div>
                <div
                    class="icon-container"
                    *ngIf="!(messageInsightsBestPracticeData[0]?.count < 2)"
                >
                    <!-- <div class="segment-average-text">
                                Commercial Segment Average:
                            </div> -->
                    <div class="square-container">
                        <div class="square square-purple"></div>
                        <span class="legend-avg-call-text">
                            Avg. of your calls</span
                        >
                    </div>
                    <div class="legend-average-container">
                        <rd-icon
                            class="progress-average-icon"
                            type="custom:progress-average"
                        ></rd-icon>
                        <span class="legend-average-text"
                            >Avg. of all rep calls​</span
                        >
                    </div>
                </div>
            </div>

            <app-insights-table
                [data]="messageInsightsBestPracticeData"
                [hideAverageDetails]="
                    messageInsightsBestPracticeData[0]?.count < 2
                "
                [isBestPractice]="true"
                [isLoading]="callMessagingInsightsLoading === 'LOADING'"
            ></app-insights-table>
        </div>
    </div>
    <div class="note-content-msg" *ngIf="scoreCardStatus === 'validated'">
        <rd-icon class="bulb-icon" type="custom:bulb"></rd-icon>
        <span class="note-content-text"
            >Disclaimer: (Placeholder) The results will be quality checked
            periodically and the results may change.</span
        >
    </div>
    <app-feedback-form
        [showAddModal]="showAddModal"
        (modalClosed)="modalClosed($event)"
        [itemList]="itemList"
        [feedbackType]="feedback.feedbackSession"
        [feedbackSessionName]="feedback.feedbackSessionName"
        [feedbackContentText]="feedback.feedbackContentText"
        [feedbackSentiment]="feedback.defaultSentiment"
        (feedbackSaved)="feedbackSave($event)"
        [saveLoading]="saveLoading"
    ></app-feedback-form>
</div>
