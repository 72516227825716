import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyCallsComponent } from './my-calls.component';
import { ScoreCardComponent } from './score-card/score-card.component';

const routes: Routes = [
    {
        path: '',
        component: MyCallsComponent
    },
    {
        path: 'score-card/:id',
        component: ScoreCardComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MyCallsRoutingModule {}
