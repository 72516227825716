<div class="table-container">
    <table>
        <thead>
            <tr *ngFor="let row of headersConfig">
                <th
                    *ngFor="let column of row"
                    [colSpan]="column.colSpan || 1"
                    [rowSpan]="column.rowSpan || 1"
                    [ngStyle]="column.style"
                >
                    <div class="header-cell">
                        <div>
                            {{
                                column.label === 'Average'
                                    ? hideAverageDetails
                                        ? ''
                                        : column.label
                                    : isBestPractice &&
                                      column.label === 'Key Message'
                                    ? 'Best Practice'
                                    : column.label
                            }}
                            <rd-tooltip
                                *ngIf="
                                    column.tooltip && column.label === 'Average'
                                        ? hideAverageDetails
                                            ? ''
                                            : column.label
                                        : false
                                "
                                [tooltipText]="column.tooltip"
                                class="header-tooltip"
                            ></rd-tooltip>
                        </div>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!isLoading">
            <tr *ngFor="let row of data">
                <td *ngFor="let column of bodyHeaders" [ngStyle]="column.style">
                    <container-element [ngSwitch]="column.type">
                        <div *ngSwitchCase="'text'">
                            {{ row[column.key] }}
                        </div>
                        <div *ngSwitchCase="'icon'" class="icon">
                            <rd-icon
                                [ngStyle]="row[column.key + '_iconStyle']"
                                [type]="row[column.key + '_icon']"
                            ></rd-icon>
                        </div>
                        <div *ngSwitchCase="'progressBar'">
                            <div
                                *ngIf="!hideAverageDetails"
                                class="progress-bar-container"
                            >
                                <div class="progress-bar">
                                    <app-progress-bar
                                        barColor="#7232CF"
                                        [percentage]="row[column?.key]"
                                        [average]="row[column?.averageKey]"
                                        averageTooltipText="
                                Avg. of all reps: {{ row[column?.averageKey] }}%
                            "
                                    ></app-progress-bar>
                                </div>
                                <!-- <rd-tooltip
                                    *ngIf="
                                        column.insightKey &&
                                        row[column.insightKey]
                                    "
                                    [tooltipText]="row[column.insightKey]"
                                    class="insight"
                                >
                                    <rd-icon type="custom:bulb"> </rd-icon>
                                </rd-tooltip> -->
                            </div>
                        </div>
                    </container-element>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="isLoading">
            <tr *ngFor="let row of [].constructor(loadingRows)">
                <td *ngFor="let column of bodyHeaders" [ngStyle]="column.style">
                    <div class="loading-cell skeleton"></div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
