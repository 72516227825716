import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { TRANSLATE_KEYS } from '../../../shared/constants/translate.keys';
import { MyCallsService } from '../my-calls.service';
import { environment } from '../../../../environments/environment';
import {
    LOADER,
    SOMETHING_WENT_WRONG
} from '../../../shared/constants/applications-constants';
import { ToasterService } from '../../../shared/components/toaster/toaster.service';
import { HomeService } from '../../../home/home.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-add-new-call',
    templateUrl: './add-new-call.component.html',
    styleUrls: ['./add-new-call.component.scss']
})
export class AddNewCallComponent implements OnChanges {
    @Input() showAddModal = false;
    @Input() editCallId: any;
    @Output() modalClosed = new EventEmitter<any>();
    callData: any = {};
    saveLoading: LOADER = 'SUCCESS';

    translateKeys = TRANSLATE_KEYS;

    formColumns: any;
    isFormInvalid = false;

    audioModel: any;
    audioUrl: any;
    fileName: any;
    audioFile: any;

    callId = '';

    consent = false;
    AE_HSIConsent = false;

    appConfig: any;

    @ViewChild('audioInput') audioInput!: ElementRef;
    @ViewChild('formParent') formParent!: ElementRef;

    constructor(
        private myCallsService: MyCallsService,
        private toasterService: ToasterService,
        private translateService: TranslateService,
        private homeService: HomeService
    ) {
        this.appConfig = this.homeService.getAppConfig();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            !changes['showAddModal']?.previousValue &&
            changes['showAddModal']?.currentValue
        ) {
            this.openModal();
        }
    }

    openModal() {
        this.updateAddCallForm();

        this.audioModel = undefined;
        this.audioUrl = undefined;
        this.fileName = undefined;
        this.audioFile = undefined;
        this.consent = false;
        this.AE_HSIConsent = false;

        this.updateDropdowns();
        if (!this.editCallId) {
            this.generateCallTitle();
        } else {
            this.patchCallEditData();
        }
        this.validateForm();

        this.showAddModal = true;
    }

    updateAddCallForm() {
        this.formColumns = JSON.parse(
            JSON.stringify(environment.clientConfig.addCallForm)
        );
    }

    generateCallTitle() {
        this.myCallsService.generateCallTitle().subscribe((res) => {
            this.formColumns.forEach((section: any) => {
                section.fields.forEach((field: any) => {
                    if (field.key === 'callTitle') {
                        field.value = res.callTitle;
                        this.callId =
                            res.callId || parseInt(res.callTitle.split('_')[1]);
                    }
                });
            });
        });
    }

    patchCallEditData() {
        this.myCallsService
            .callData({ id: this.editCallId })
            .subscribe((res) => {
                this.audioUrl = res.fileUrl;
                this.audioFile = res.fileUrl;
                this.fileName = res.audioPath.split('/').reverse()[0];
                this.callId = res.callId;
                this.AE_HSIConsent = res?.isAE_HSIConsent || false;
                this.formColumns.forEach((section: any) => {
                    section.fields.forEach((field: any) => {
                        if (field.key in res) {
                            field.value = res[field.key];
                        }
                        if (field.key + 'Id' in res) {
                            field.value = res[field.key + 'Id'];
                        }
                        if (field?.id) {
                            field.value = res.segments?.filter(
                                (segment: any) => segment.segmentId === field.id
                            )[0].valueId;
                        }
                        if (field.cascadedTo?.length) this.validateForm(field);
                    });
                });
                this.validateForm();
            });
    }

    closeModal() {
        this.showAddModal = false;
        this.modalClosed.emit();
    }

    handleChange(event: any) {
        if (['audio/wav', 'audio/x-m4a'].includes(event.target.files[0].type)) {
            this.audioUrl = URL.createObjectURL(event.target.files[0]);
            this.fileName = event.target.files[0].name;
            this.audioFile = event.target.files[0];
        } else {
            this.audioModel = undefined;
            this.audioUrl = undefined;
            this.fileName = undefined;
            this.audioFile = undefined;
        }
    }

    fileDropped(event: any) {
        this.audioUrl = URL.createObjectURL(event[0]);
        this.fileName = event[0].name;
        this.audioFile = event[0];
    }

    deleteClick() {
        this.audioUrl = undefined;
        this.audioModel = undefined;
        this.audioFile = undefined;
    }

    updateDropdowns() {
        this.formColumns.forEach((section: any) => {
            section.fields.forEach((field: any) => {
                if (
                    field.fieldType === 'select' &&
                    !field?.cascadedFrom?.length
                ) {
                    this.fetchDropdowns(field);
                }
            });
        });
    }

    fetchDropdowns(field: any, filters = {}) {
        const payload = {
            filters: {
                countryId: this.appConfig.countryId,
                drugId: this.appConfig.drugId,
                ...filters
            },
            dropdownType: field.key
        };
        field.loading = true;
        this.myCallsService.fetchDropdowns(payload).subscribe(
            (res) => {
                if (field?.sortOptions)
                    res.dropdown = res.dropdown.sort((a: any, b: any) =>
                        a.label.localeCompare(b.label)
                    );
                const othersIndex = res.dropdown.findIndex(
                    (item: any) => item.label === 'Others'
                );
                if (othersIndex > -1) {
                    const element = res.dropdown[othersIndex];
                    res.dropdown.splice(othersIndex, 1);
                    res.dropdown.push(element);
                }
                field.options = res.dropdown;
                field.loading = false;
            },
            (error) => {
                this.showNotification('error', SOMETHING_WENT_WRONG);
            }
        );
    }

    onModalScroll(event: any) {
        this.formParent?.nativeElement.click();
    }

    save() {
        if (this.editCallId) {
            this.updateApiCall();
        } else {
            this.saveApiCall();
        }
    }

    updatePayload(payload: any) {
        const segmentKeys = this.appConfig.segments.map(
            (segment: any) => segment.key
        );
        payload['segments'] = [];

        this.formColumns.forEach((section: any) => {
            section.fields.forEach((field: any) => {
                payload[
                    `${field.key}${field.fieldType === 'select' ? 'Id' : ''}`
                ] = field.value || null;
                if (field.key === 'specialty') {
                    payload['specialty'] = field.options.filter(
                        (item: any) => item.value === field.value
                    )[0].label;
                }
                if (segmentKeys.includes(field.key)) {
                    payload.segments.push({
                        valueId: field.value || null,
                        valueLabel:
                            field.options.filter(
                                (item: any) => item.value === field.value
                            )[0]?.label || null,
                        segmentId: field.id
                    });
                }
            });
        });
        return payload;
    }

    updateApiCall() {
        let payload: any = {
            id: this.editCallId,
            callId: this.callId,
            countryId: this.appConfig.countryId,
            drugId: this.appConfig.drugId,
            isAE_HSIConsent: this.AE_HSIConsent
        };
        payload = this.updatePayload(payload);
        this.saveLoading = 'LOADING';
        this.myCallsService.editCall(payload).subscribe(
            (res) => {
                this.saveLoading = 'SUCCESS';
                this.showNotification(
                    'success',
                    this.translateKeys.newCall.saveEditSuccessMessage
                );
                this.closeModal();
                this.modalClosed.emit(true);
            },
            (err) => {
                this.saveLoading = 'FAILED';
                this.showNotification(
                    'error',
                    this.translateKeys.common.somethingWentWrong
                );
            }
        );
    }

    saveApiCall() {
        let payload: any = {
            callId: this.callId,
            fileName: this.fileName,
            segments: [],
            countryId: this.appConfig.countryId,
            drugId: this.appConfig.drugId,
            isAE_HSIConsent: this.AE_HSIConsent
        };

        payload = this.updatePayload(payload);

        this.fetchPresignedUrl(payload);
    }

    fetchPresignedUrl(payload: any) {
        this.saveLoading = 'LOADING';
        this.myCallsService.requestUpload(payload).subscribe(
            (res) => {
                this.uploadAudioToS3(res, this.audioFile, payload);
            },
            (err) => {
                this.saveLoading = 'FAILED';
                this.showNotification(
                    'error',
                    this.translateKeys.common.somethingWentWrong
                );
            }
        );
    }

    uploadAudioToS3(data: any, file: any, payload: any) {
        this.myCallsService.uploadAudioToS3(data, file).subscribe(
            (res) => {
                this.saveCall(payload);
            },
            (err) => {
                this.saveLoading = 'FAILED';
                this.showNotification(
                    'error',
                    this.translateKeys.common.somethingWentWrong
                );
            }
        );
    }

    saveCall(payload: any) {
        this.myCallsService.saveCall(payload).subscribe(
            (res) => {
                this.saveLoading = 'SUCCESS';
                this.showNotification(
                    'success',
                    this.translateKeys.newCall.saveSuccessMessage
                );
                this.closeModal();
                this.modalClosed.emit(true);
            },
            (err) => {
                this.saveLoading = 'FAILED';
                this.showNotification(
                    'error',
                    this.translateKeys.common.somethingWentWrong
                );
            }
        );
    }

    showNotification(type: notification = 'warning', messageKey = 'Message') {
        this.translateService.get(messageKey).subscribe((res) => {
            const message = res;
            switch (type) {
                case 'warning':
                    this.toasterService.toast('warning', message);
                    break;
                case 'success':
                    this.toasterService.toast('success', message);
                    break;
                case 'error':
                    this.toasterService.toast('error', message);
                    break;
            }
        });
    }

    identifyCascadingFilters(inputField: any) {
        let filters: any = {};

        this.formColumns.forEach((section: any) => {
            section.fields.forEach((field: any) => {
                if (
                    inputField?.cascadedFrom?.includes(field.key) &&
                    field.value
                ) {
                    filters[`${field.key}${'Id'}`] = field.value || null;
                }
            });
        });
        return filters;
    }

    validateForm(event: any = null) {
        let validFlag = false;
        this.formColumns.forEach((section: any) => {
            section.fields.forEach((field: any) => {
                if (field.isMandatory && !field.value) validFlag = true;

                if (event?.cascadedTo?.includes(field.key)) {
                    if (event.value) {
                        this.fetchDropdowns(
                            field,
                            this.identifyCascadingFilters(field)
                        );
                    } else {
                        field.options = [];
                    }
                    field.value = null;
                }
            });
        });
        this.isFormInvalid = validFlag;
    }

    disableSave() {
        return !this.audioFile || !this.consent || this.isFormInvalid;
    }
}

export type notification = 'error' | 'success' | 'warning';
