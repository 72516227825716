import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './progress-bar.component';
import { RDIconModule, RDTooltipModule } from '@zs-ca/angular-cd-library';
@NgModule({
    declarations: [ProgressBarComponent],
    imports: [CommonModule, RDIconModule, RDTooltipModule],
    exports: [ProgressBarComponent]
})
export class ProgressBarModule {}
