import { environment } from '../../../environments/environment';

let LOCAL_API_ENDPOINTS;
let SERVER_API_ENDPOINTS;

LOCAL_API_ENDPOINTS = {
    userinfo: 'user-info',
    launchBar: 'launch-bar',
    callDropdowns: 'call-dropdown',
    generateCallTitle: 'generate-call-title',
    requestUpload: 'request-call-upload',
    saveCall: 'save-call',
    callsGrid: 'my-calls',
    callData: 'call-data',
    editCall: 'edit-call',
    appConfig: 'configurations',
    feedback: 'feedback',
    callAnalysis: 'call-analysis',
    callSummaryInsights: 'call-summary-insights',
    callMessagingInsights: 'call-messaging-insights'
};

SERVER_API_ENDPOINTS = {
    userinfo: 'user-info',
    launchBar: 'launch-bar',
    callDropdowns: 'call-dropdown',
    generateCallTitle: 'generate-call-title',
    requestUpload: 'request-call-upload',
    saveCall: 'save-call',
    callsGrid: 'my-calls',
    callData: 'call-data',
    editCall: 'edit-call',
    appConfig: 'configurations',
    feedback: 'feedback',
    callAnalysis: 'call-analysis',
    callSummaryInsights: 'call-summary-insights',
    callMessagingInsights: 'call-messaging-insights'
};

export const API_ENDPOINTS =
    environment.serverUrl === 'local'
        ? LOCAL_API_ENDPOINTS
        : SERVER_API_ENDPOINTS;
