<rd-header [appName]="translateKeys.applicationName | translate">
    <div class="language-selection" ngProjectAs="[rd-header-content]">
        <!--        <rd-select-->
        <!--                (selectedItemsChange)="changeLanguage($event)"-->
        <!--                [isKeyLabelOptions]="true"-->
        <!--                [mode]="'default'"-->
        <!--                [options]="languages"-->
        <!--                [placeholder]="'Language'"-->
        <!--                [selectSize]="'small'"-->
        <!--                [selectedItems]="currentLanguage">-->
        <!--        </rd-select>-->
    </div>
</rd-header>
