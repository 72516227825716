import { Component, OnInit } from '@angular/core';
import { TRANSLATE_KEYS } from 'src/app/shared/constants/translate.keys';
import { Router, ActivatedRoute } from '@angular/router';
import { MyCallsService } from '../my-calls.service';
import {
    hcpParticipation,
    repParticipation,
    durationChartOptions
} from './graph.config';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'src/app/shared/components/toaster/toaster.service';
import { HomeService } from '../../../home/home.service';
import { SCORE_CARD_SECTIONS } from 'src/app/shared/constants/applications-constants';
import { LOADER } from '../../../shared/constants/applications-constants';
@Component({
    selector: 'app-score-card',
    templateUrl: './score-card.component.html',
    styleUrls: ['./score-card.component.scss']
})
export class ScoreCardComponent implements OnInit {
    translateKeys = TRANSLATE_KEYS;
    sections = SCORE_CARD_SECTIONS;
    saveLoading: LOADER = 'SUCCESS';
    callId: any;
    callAnalysisDetails: any;
    itemList = [
        {
            label: '',
            value: 'true',
            icon: {
                type: 'custom:thumps-up',
                style: {
                    fontSize: '18px',
                    color: '#4FCEA3'
                }
            }
        },
        {
            label: '',
            value: 'false',
            icon: {
                type: 'custom:thumps-down',
                style: {
                    fontSize: '18px',
                    color: '#BD4558'
                }
            }
        }
    ];
    feedback = {
        feedbackSession: '',
        feedbackSessionName: '',
        defaultSentiment: 'true',
        feedbackContentText:
            'Thanks in advance for sharing your feedback with us. Please add any comments you feel necessary on the box below. Detailed feedback would help us improve your experience using the app and refine the insights we can provide.'
    };

    showAddModal = false;
    callDataLoading: LOADER = 'LOADING';
    callAnalysisDataLoading: LOADER = 'LOADING';
    callSummaryDataLoading: LOADER = 'LOADING';
    callMessagingInsightsLoading: LOADER = 'LOADING';
    breadcrumbData = [
        { navigationPath: '/my-calls', displayName: 'My Call' },
        { navigationPath: '', displayName: 'Call Scorecard' }
    ];

    callDetails: any;

    hcpChartOptions = hcpParticipation;
    repChartOptions = repParticipation;
    durationChartOptions = durationChartOptions;
    data: any = [];
    messageInsightsData: any = [];

    messageInsightsBestPracticeData: any = [];

    messageInsightsKeyMessageData: any = [];

    max = 50;

    appConfig: any;

    scoreCardStatus = localStorage.getItem('scoreCardStatus');

    callSummaryData: any = [];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private myCallsService: MyCallsService,
        private toasterService: ToasterService,
        private translateService: TranslateService,
        private homeService: HomeService
    ) {
        this.appConfig = this.homeService.getAppConfig();
        this.callId = parseInt(this.activatedRoute.snapshot?.params['id']);
    }

    ngOnInit() {
        this.scoreCardStatus = localStorage.getItem('scoreCardStatus');
        this.getCallData();
    }

    dataFormatting() {
        const bgTop: any = [];
        const bgBottom: any = [];
        const hcp: any = [];
        const rep: any = [];
        const bgColors: any = [];
        const hcpColors: any = [];
        const repColors: any = [];
        const sentimentColorDict: any = {
            neutral: '#ffff',
            negative: '#AE19310D',
            positive: '#F4FCF9',
            mixed: '#ffff'
        };

        const typeColorDict: any = {
            question: '#027AFF',
            keymessage: '#26C38D',
            concern: '#FF9836'
        };
        const max = this.getMaxOfDuration();
        this.data.forEach((item: any) => {
            if (item.speaker === 'rep') {
                rep.push(item.duration);
                hcp.push(0);
                bgTop.push(max - item.duration);
                bgBottom.push(0 - max);
                hcpColors.push('#9C9AA1');
                repColors.push(typeColorDict[item.type?.toLowerCase()]);
            } else {
                rep.push(-0);
                hcp.push(-item.duration);
                bgTop.push(max);
                bgBottom.push(-(max - item.duration));
                repColors.push('#9C9AA1');
                hcpColors.push(typeColorDict[item.type?.toLowerCase()]);
            }
            bgColors.push(
                sentimentColorDict[item.sentiment?.toLowerCase()] ||
                    sentimentColorDict.neutral
            );
        });
        this.durationChartOptions.series[0].data = bgTop;
        this.durationChartOptions.series[0].colors = bgColors;
        this.durationChartOptions.series[0].name = 'bgTop';

        this.durationChartOptions.series[1].data = bgBottom;
        this.durationChartOptions.series[1].colors = bgColors;
        this.durationChartOptions.series[1].name = 'bgBottom';

        this.durationChartOptions.series[2].data = hcp;
        this.durationChartOptions.series[2].colors = hcpColors;
        this.durationChartOptions.series[2].name = 'hcp';

        this.durationChartOptions.series[3].data = rep;
        this.durationChartOptions.series[3].colors = repColors;
        this.durationChartOptions.series[3].name = 'rep';
    }

    getMaxOfDuration() {
        return Math.max(
            ...this.data.map((item: any) => {
                return item.duration;
            })
        );
    }
    getCallAnalysisDetails() {
        this.callAnalysisDataLoading = 'LOADING';
        this.myCallsService
            .callAnalysis({
                id: this.callDetails.id,
                callId: this.callDetails.callId,
                countryId: this.callDetails.countryId,
                drugId: this.appConfig.drugId,
                callTitle: this.callDetails.callTitle,
                specialtyId: this.callDetails.specialtyId
            })
            .subscribe(
                (res) => {
                    res.callDuration = this.callDuration(res.callDuration);
                    this.callAnalysisDetails = res;
                    if (res?.count < 2) {
                        this.hcpChartOptions.series[0].data = [
                            [res.hcpVoicePercentage]
                        ];
                        this.repChartOptions.series[0].data = [
                            [res.askedQuestions]
                        ];
                        this.hcpChartOptions.yAxis.plotLines[0].value =
                            res.hcpVoicePercentageAvg;
                        // this.hcpChartOptions.yAxis.plotLines[0].label.useHTML =
                        //     false;
                        // this.hcpChartOptions.yAxis.plotLines[0].label.useHTML =
                        //     false;
                    } else {
                        this.hcpChartOptions.series[0].data = [
                            [res.hcpVoicePercentage],
                            [res.hcpVoicePercentageHAvg]
                        ];
                        this.hcpChartOptions.yAxis.plotLines[0].value =
                            res.hcpVoicePercentageAvg;

                        this.repChartOptions.series[0].data = [
                            [res.askedQuestions],
                            [res.askedQuestionsAvg]
                        ];
                        this.repChartOptions.yAxis.plotLines[0].value =
                            res.topPerformerQuestionAvg;
                    }

                    this.callAnalysisDataLoading = 'SUCCESS';
                },
                (error) => {
                    this.callAnalysisDataLoading = 'FAILED';
                    if (error) {
                        this.showNotification(
                            'error',
                            this.translateKeys.common.somethingWentWrong
                        );
                    }
                }
            );
    }
    callDuration(d: any) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);

        var hDisplay = h > 0 ? h + 'H ' : '';
        var mDisplay = m > 0 ? m + 'M ' : '';
        var sDisplay = s > 0 ? s + 'S' : '';
        return hDisplay + mDisplay + sDisplay;
    }
    getCallData() {
        this.callDataLoading = 'LOADING';
        this.myCallsService.callData({ id: this.callId }).subscribe(
            (res) => {
                this.callDetails = res;
                if (this.callDetails?.segments) {
                    this.callDetails.segments.forEach((segment: any) => {
                        segment['label'] = this.appConfig.segments.filter(
                            (item: any) => item.id === segment.segmentId
                        )[0].label;
                    });
                }
                if (this.scoreCardStatus === 'partially completed') {
                    this.callSummaryInsights();
                } else if (this.scoreCardStatus === 'validated') {
                    this.callSummaryInsights();
                    this.getCallAnalysisDetails();
                    this.callMessagingInsights();
                }
                this.callDataLoading = 'SUCCESS';
            },
            (error) => {
                this.callDataLoading = 'FAILED';
                if (error) {
                    this.showNotification(
                        'error',
                        this.translateKeys.common.somethingWentWrong
                    );
                }
            }
        );
    }

    callMessagingInsights() {
        this.callMessagingInsightsLoading = 'LOADING';
        let segmentId = this.appConfig.segments.filter(
            (segment: any) => segment.key === 'commercial'
        );
        segmentId = segmentId.length
            ? segmentId[0].id
            : this.appConfig.segments[0].id;

        this.myCallsService
            .callMessagingInsights({
                id: this.callDetails.id,
                callId: this.callDetails.callId,
                countryId: this.callDetails.countryId,
                drugId: this.appConfig.drugId,
                callTitle: this.callDetails.callTitle,
                specialtyId: this.callDetails.specialtyId,
                segmentId: segmentId
            })
            .subscribe(
                (res) => {
                    this.messageInsightsData = res;
                    if (this.messageInsightsData['keyMessages']?.length) {
                        this.messageInsightsKeyMessageData =
                            this.transfromMessageInsightData(
                                this.messageInsightsData['keyMessages']
                            );
                    }
                    if (this.messageInsightsData['bestPractices']?.length) {
                        this.messageInsightsBestPracticeData =
                            this.transfromMessageInsightData(
                                this.messageInsightsData['bestPractices']
                            );
                    }
                    this.callMessagingInsightsLoading = 'SUCCESS';
                },
                (error) => {
                    this.callMessagingInsightsLoading = 'FAILED';
                    if (error) {
                        this.showNotification(
                            'error',
                            this.translateKeys.common.somethingWentWrong
                        );
                    }
                }
            );
    }

    transfromMessageInsightData(arr: any) {
        arr.forEach((item: any) => {
            if (item?.isInformed) {
                item['isInformed_icon'] = 'check-circle';
                item['isInformed_iconStyle'] = {
                    color: '#4FCEA3'
                };
            } else if (!item?.isInformed) {
                item['isInformed_icon'] = 'close-circle';
                item['isInformed_iconStyle'] = {
                    color: '#BD4558'
                };
            }

            if (item?.isTriggered) {
                item['isTriggered_icon'] = 'check-circle';
                item['isTriggered_iconStyle'] = {
                    color: '#4FCEA3'
                };
            } else if (!item?.isTriggered) {
                item['isTriggered_icon'] = 'close-circle';
                item['isTriggered_iconStyle'] = {
                    color: '#BD4558'
                };
            }
        });
        return arr;
    }
    callSummaryInsights() {
        this.callSummaryDataLoading = 'LOADING';
        this.myCallsService
            .callSummaryInsights({
                id: this.callDetails.id,
                callId: this.callDetails.callId,
                countryId: this.callDetails.countryId,
                drugId: this.appConfig.drugId,
                callTitle: this.callDetails.callTitle,
                specialtyId: this.callDetails.specialtyId
            })
            .subscribe(
                (res) => {
                    this.data = res;
                    this.callSummaryData = [...this.data];
                    // this.dataFormatting();
                    this.callSummaryDataLoading = 'SUCCESS';
                },
                (error) => {
                    this.callSummaryDataLoading = 'FAILED';
                    if (error) {
                        this.showNotification(
                            'error',
                            this.translateKeys.common.somethingWentWrong
                        );
                    }
                }
            );
    }
    showNotification(type: notification = 'warning', messageKey = 'Message') {
        this.translateService.get(messageKey).subscribe((res: any) => {
            const message = res;
            switch (type) {
                case 'warning':
                    this.toasterService.toast('warning', message);
                    break;
                case 'success':
                    this.toasterService.toast('success', message);
                    break;
                case 'error':
                    this.toasterService.toast('error', message);
                    break;
            }
        });
    }

    openFeedbackPopup(session: any, sessionName: any, defaultSentiment: any) {
        this.feedback.feedbackSession = session;
        this.feedback.feedbackSessionName = sessionName;
        this.feedback.defaultSentiment = defaultSentiment;
        this.showAddModal = true;
    }
    modalClosed(saved: any) {
        this.showAddModal = false;
        this.feedback.feedbackSession = '';
        this.feedback.defaultSentiment = '';
    }
    feedbackSave(feedbackSave: any) {
        this.saveLoading = 'LOADING';
        this.myCallsService
            .feedback({
                callId: this.callDetails.callId,
                id: this.callDetails.id,
                callTitle: this.callDetails.callTitle,
                countryId: this.callDetails.countryId,
                specialtyId: this.callDetails.specialtyId,
                section: this.feedback.feedbackSessionName,
                feedback: feedbackSave.feedback,
                feedbackSentiment: feedbackSave.feedbackSentiment === 'true',
                drugId: this.appConfig.drugId
            })
            .subscribe(
                (res) => {
                    this.saveLoading = 'SUCCESS';
                    this.showAddModal = false;
                    this.showNotification(
                        'success',
                        this.translateKeys.common.feedbackSave
                    );
                },
                (error) => {
                    this.saveLoading = 'FAILED';
                    if (error) {
                        this.showNotification(
                            'error',
                            this.translateKeys.common.somethingWentWrong
                        );
                    }
                }
            );
    }
}

export type notification = 'error' | 'success' | 'warning';
