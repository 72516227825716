import { AfterViewInit, Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TRANSLATE_KEYS } from '../../shared/constants/translate.keys';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements AfterViewInit {
    @Input() siderData?: LaunchBarData;

    @Input() menuItemsList = [
        {
            label: 'My Calls',
            path: '/my-calls',
            icon: 'custom:phone',
            disabled: false,
            translateKey: TRANSLATE_KEYS.sideBar.myCalls
        }
    ];

    showHelpModal = false;

    documentFetchInProgress = false;

    readonly contactSupportList = [
        {
            label: 'Email',
            value: 'support@zs.com',
            href: 'mailto:support@zs.com'
        },
        {
            label: 'Phone',
            value: 'U.S.: 877-393-9775',
            href: 'tel:8773939775'
        }
    ];
    readonly releaseVersion = 'R1.0';

    constructor(
        private http: HttpClient,
        private router: Router,
        private translateService: TranslateService
    ) {}

    ngAfterViewInit() {
        this.updateSidebarLabels();
        this.translateService.onLangChange.subscribe((values) => {
            this.updateSidebarLabels();
        });
    }

    updateSidebarLabels() {
        this.menuItemsList.forEach((item) => {
            this.translateService
                .get(item.translateKey)
                .subscribe((res: string) => {
                    item.label = res;
                });
        });
    }

    toggleSupportModal($event: boolean): void {
        this.showHelpModal = $event;
    }

    handleBrandClick(): void {
        this.router.navigateByUrl('/');
    }

    menuItemClick(event: any) {
        // console.log(event)
    }
}

export interface LaunchBarData {
    applications: {
        name: string;
        title: string;
        url: string;
    }[];
    expiry: { contactSupportLink: string; nonzs: string; zs: string };
    myAccount: string;
    signOut: string;
}
