import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    private toasterSubject: Subject<any> = new Subject<any>();

    constructor() {}

    toast(type: Toaster, message: string) {
        this.toasterSubject.next({
            type,
            message
        });
    }

    listenForToast() {
        return this.toasterSubject;
    }
}

export type Toaster = 'error' | 'success' | 'warning';
