import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent {
    @Input() formColumns: any;

    @Output() validateForm = new EventEmitter<any>();

    isOpenDropdown = false;

    inputStyle = {
        height: '40px'
    };

    selectStyle = {
        height: '40px',
        width: '100%'
    };
}
