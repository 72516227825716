import {
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    NgModule,
    Output
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

@Directive({
    selector: '[appDnd]'
})
export class DragAndDropDirective {
    @Output() file: EventEmitter<any> = new EventEmitter();

    @HostBinding('style.background') private background = '#fafafa';

    constructor(private sanitizer: DomSanitizer) {}

    @HostListener('dragover', ['$event'])
    public onDragOver(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#999';
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#fff';
    }

    @HostListener('drop', ['$event'])
    public onDrop(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#fff';
        if (
            evt?.dataTransfer?.files?.length === 1 &&
            ['audio/wav', 'audio/x-m4a'].includes(
                evt.dataTransfer.files[0].type
            )
        ) {
            this.file.emit(evt.dataTransfer.files);
        }
    }
}

@NgModule({
    declarations: [DragAndDropDirective],
    exports: [DragAndDropDirective],
    imports: [CommonModule]
})
export class DragAndDropModule {}
